import React, { useContext, useState, useEffect } from 'react';
import VZSideBarNav from './side-bar-nav/index';
import { match, Switch, Route, Redirect } from 'react-router';
import { Location } from 'history';
import style from './index.module.scss';
import VZTeamsPage from './teams-page';
import VZProfilePage from './profile-page';
import { connect } from 'react-redux';
import { VZToastProvider, ToastContext, IVZToastContext, UserContext, VZSocketProvider } from 'vzc-client-common';

import 'react-virtualized/styles.css'; // From react-virtualized-only needs to be imported once
import { VZTeamContextProvider } from './shared/team-context';
import { authAPI } from 'vzc-client-common';
import VZCompanyPage from './company-page';

interface VZDashboardProps {
    match: match;
    location: Location;
}

export default function VZDashboard(props: VZDashboardProps) {
    const userContext = useContext(UserContext);

    if (!userContext.isLoggedin) {
        let afterLogin = props.location.pathname + props.location.search;
        if (afterLogin !== '/dashboard/login') {
            return <Redirect to={`/login?afterLogin=${afterLogin}`}></Redirect>;
        } else {
            return <Redirect to={`/login`} />;
        }
    }

    return (
        <VZSocketProvider>
            <VZTeamContextProvider>
                <React.Fragment>
                    <div className={style.dashboard}>
                        <VZSideBarNav match={props.match} />
                        <div className={style.rightSide}>
                            <Switch>
                                <Redirect exact={true} from={`${props.match.path}/`} to={`${props.match.url}/teams`} />
                                <Route
                                    path={`${props.match.path}/complete-request/:id/:verificationCode`}
                                    render={createActionRunner(props.match, onOpenCompleteRequest)}
                                />
                                <Route
                                    path={`${props.match.path}/reject-request/:id/:verificationCode`}
                                    render={createActionRunner(props.match, onOpenRejectRequest)}
                                />

                                <Route
                                    path={`${props.match.path}/complete-invite/:id/:verificationCode`}
                                    render={createActionRunner(props.match, onOpenCompleteInvite)}
                                />

                                <Route
                                    path={`${props.match.path}/reject-invite/:id/:verificationCode`}
                                    render={createActionRunner(props.match, onOpenRejectInvite)}
                                />

                                <Redirect from={`${props.match.path}/`} to={`${props.match.url}/teams`} exact={true} />
                                <Route path={`${props.match.path}/teams`} component={VZTeamsPage} />
                                <Route path={`${props.match.path}/company`} component={VZCompanyPage} />
                                <Route path={`${props.match.path}/profile`} component={VZProfilePage} />
                                <Route path={`${props.match.path}/billing`} component={VZTeamsPage} />
                                <Route path={`${props.match.path}/help`} component={VZTeamsPage} />
                            </Switch>
                        </div>
                    </div>
                </React.Fragment>
            </VZTeamContextProvider>
        </VZSocketProvider>
    );

    async function onOpenCompleteRequest(toastContext: IVZToastContext, params: { id: string; verificationCode: string }) {
        if (!userContext.authToken) {
            return;
        }

        try {
            await authAPI.requests.completeRequest(userContext.authToken, params.id, params.verificationCode);

            toastContext.showToast('Request complete!');
        } catch (err) {
            toastContext.showError(err);
        }
    }

    async function onOpenRejectRequest(toastContext: IVZToastContext, params: { id: string; verificationCode: string }) {
        if (!userContext.authToken) {
            return;
        }

        try {
            await authAPI.requests.rejectRequest(userContext.authToken, params.id, params.verificationCode);

            toastContext.showToast('Request Rejected!');
        } catch (err) {
            toastContext.showError(err);
        }
    }

    async function onOpenCompleteInvite(toastContext: IVZToastContext, params: { id: string; verificationCode: string }) {
        if (!userContext.authToken) {
            return;
        }

        try {
            await authAPI.invites.acceptInvite(userContext.authToken, params.id, params.verificationCode);

            toastContext.showToast('Invite Accepted!');
        } catch (err) {
            toastContext.showError(err);
        }
    }

    async function onOpenRejectInvite(toastContext: IVZToastContext, params: { id: string; verificationCode: string }) {
        if (!userContext.authToken) {
            return;
        }

        try {
            await authAPI.invites.rejectInvite(userContext.authToken, params.id, params.verificationCode);

            toastContext.showToast('Invite Rejected!');
        } catch (err) {
            toastContext.showError(err);
        }
    }

    function createActionRunner<TParams>(match: match, onOpen: (toastContext: IVZToastContext, params: TParams) => void) {
        return (props: { location: Location; match: match<TParams> }) => {
            return <VZDashboardActionRunner baseUrl={match.url} {...props} onOpen={onOpen} />;
        };
    }
}

function VZDashboardActionRunner<TParams>(props: {
    baseUrl: string;
    onOpen: (toastContext: IVZToastContext, params: TParams) => void;
    location: Location;
    match: match<TParams>;
}) {
    const toastContext = useContext(ToastContext);

    useEffect(() => {
        props.onOpen(toastContext, props.match.params);
    }, []);

    return <Redirect to={props.baseUrl} />;
}
