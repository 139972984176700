import { TeamContext } from 'dashboard/shared/team-context';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { match } from 'react-router-dom';
import VZConnectedAvatarBar from '../avatar-bar';
import VZNavTabBar from '../nav-tab-bar';
import VZConnectionsTab from './connections-tab';
import style from './index.module.scss';
import VZManageTab from './manage-tab';
import VZMembersTab from './members-tab';
import VZRolesTab from './roles-tab';
import { UserContext, VZUserIcon, useLocale } from 'vzc-client-common';
import VZAddTeamModal from 'dashboard/add-team-modal';

export default function VZTeamsPage(props: { match: match }) {
    const userContext = useContext(UserContext);
    const teamContext = useContext(TeamContext);
    const locale = useLocale();
    const [showAddTeamModal, setShowAddTeamModal] = useState(false);

    return (
        <React.Fragment>
            {showAddTeamModal && <VZAddTeamModal closeModal={() => setShowAddTeamModal(false)} />}
            <div className={style.pageContainer}>
                <div className={style.headerContainer}>
                    <VZConnectedAvatarBar />
                    <h1 className={style.headerTitle}>{locale.formatMessage('dashboard.menu.teams')}</h1>
                    {renderTeamSelector()}
                    <VZNavTabBar tabs={getTabs()} />
                </div>
                <Switch>
                    <Redirect exact={true} from={`${props.match.path}/`} to={`${props.match.url}/connections`} />
                    <Route path={`${props.match.path}/connections`} component={VZConnectionsTab} />
                    <Route path={`${props.match.path}/members`} component={VZMembersTab} />
                    <Route path={`${props.match.path}/roles`} component={VZRolesTab} />
                    <Route path={`${props.match.path}/billing`} />
                    <Route path={`${props.match.path}/manage`} component={VZManageTab} />
                </Switch>
            </div>
        </React.Fragment>
    );

    function getTabs() {
        return [
            { label: locale.formatMessage('dashboard.teams.connections'), url: `${props.match.url}/connections` },
            { label: locale.formatMessage('dashboard.teams.roles'), url: `${props.match.url}/roles` },
            { label: locale.formatMessage('dashboard.teams.members'), url: `${props.match.url}/members` },
            { label: locale.formatMessage('dashboard.teams.billing'), url: `${props.match.url}/billing` },
            { label: locale.formatMessage('dashboard.teams.manage'), url: `${props.match.url}/manage` },
        ];
    }

    function renderTeamSelector() {
        if (teamContext.currentTeam) {
            return (
                <div className={style.teamInfo}>
                    <VZUserIcon
                        className={style.teamImg}
                        src={teamContext.currentTeam.iconUrl}
                        defaultText={teamContext.currentTeam.name.substr(0, 2)}
                    />
                    <div className={style.currentTeam}>{teamContext.currentTeam.name}</div>
                </div>
            );
        }

        const addTeamLinkMessage = locale.formatHTMLMessage('dashboard.teams.link_to_add_team', {
            a: (msg) => (
                <span className={style.actionText} onClick={openTeamModal}>
                    {msg}
                </span>
            ),
        });

        return (
            <React.Fragment>
                <p className={style.noTeamMessage}>{locale.formatMessage('dashboard.teams.no_teams_configured')}</p>
                <p className={style.noTeamAction}>{addTeamLinkMessage}</p>
            </React.Fragment>
        );
    }

    function openTeamModal() {
        setShowAddTeamModal(true);
    }
}
