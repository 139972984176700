import React from 'react';

import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

// import style from './index.module.scss';

interface vzLogoutComponentProps {}

type vzLogoutComponentState = {};

export class vzLogout extends React.Component<vzLogoutComponentProps, vzLogoutComponentState> {
    constructor(props: vzLogoutComponentProps) {
        super(props);
    }

    render() {
        localStorage.clear();

        if (localStorage.getItem('token')) {
            return <Redirect to="/dashboard"></Redirect>;
        } else {
            ReactGA.event({
                category: 'User',
                action: 'User logged out',
            });
            return <Redirect to="/"></Redirect>;
        }
    }
}

export default vzLogout;
