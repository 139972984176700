import React from 'react';

import style from './index.module.scss';
import deleteImg from './delete.svg';
import editImg from './edit.svg';
import enterImg from './enter.svg';
import editConnection from './edit-connection.svg';
import { combineClassNames } from 'vzc-client-common';

// Action type automatically selects an image for common actions.
export enum VZRowButtonType {
    DELETE = 'DELETE',
    EDIT = 'EDIT',
    ENTER = 'ENTER',
    EDIT_CONNECTION = 'EDIT_CONNECTION',
}

export enum VZRowButtonTextStyle {
    ERROR = 'ERROR',
}

interface IVZRowButtonProps {
    onClick?: () => void;
    actionType?: VZRowButtonType;
    label?: string;
    textStyle?: VZRowButtonTextStyle;
}

// Image button that displays in table rows.
export default function VZRowButton(props: IVZRowButtonProps) {
    let imgSrc = '';

    switch (props.actionType) {
        case VZRowButtonType.DELETE:
            imgSrc = deleteImg;
            break;
        case VZRowButtonType.EDIT:
            imgSrc = editImg;
            break;
        case VZRowButtonType.ENTER:
            imgSrc = enterImg;
            break;
        case VZRowButtonType.EDIT_CONNECTION:
            imgSrc = editConnection;
            break;
    }

    // Note: Currently only supports icon or label, not both!
    let mainClassName = '';
    if (props.actionType) {
        mainClassName = style.iconOnly;
    } else if (props.label) {
        mainClassName = style.labelOnly;
    }

    let textStyleClassName: string | null = null;
    switch (props.textStyle) {
        case VZRowButtonTextStyle.ERROR:
            textStyleClassName = style.textStyleError;
            break;
    }

    return (
        <div className={combineClassNames(mainClassName, textStyleClassName)} onClick={props.onClick}>
            {imgSrc !== '' && <img className={style.icon} src={imgSrc} />} {props.label}
        </div>
    );
}
