import { useState } from 'react';

type IVZFormFieldInitialValues<TObject extends object> = {
    [Prop in keyof TObject]: TObject[Prop];
};

type IVZFormFieldErrors<TObject extends object> = {
    [Prop in keyof TObject]: string;
};

type IVZFormFieldDef = {
    type: 'string' | 'number';
    required?: boolean;
    minLength?: number;
    maxLength?: number;
};

type IVZFormFieldDefs<TObject extends object> = {
    [Prop in keyof TObject]: IVZFormFieldDef;
};

type IVZFormFields<TObject extends object> = {
    [Prop in keyof TObject]: {
        value: TObject[Prop];
        error: string;
        setValue: (newValue: TObject[Prop]) => void;
    };
};

export type IVZForm<TObject extends object> = {
    isSubmitting: boolean;
    isValid: boolean;

    submit: () => void;
};

export default function useForm<
    TDefaultValues extends IVZFormFieldInitialValues<TDefaultValues>,
    TFormFieldDefs extends IVZFormFieldDefs<TDefaultValues>
>(
    initialValues: TDefaultValues,
    fieldsDefs: TFormFieldDefs,
    submitHandler: () => Promise<any>
): [IVZForm<TDefaultValues>, IVZFormFields<TDefaultValues>] {
    const [values, setValues] = useState<TDefaultValues>(initialValues);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const errors = getErrors();

    const properties = getProperties();

    const fields: { [index: string]: any } = {};

    for (const property of properties) {
        fields[property] = {
            error: validateField(property, values[property]),
            value: values[property],
            setValue: (newValue: TDefaultValues[keyof TDefaultValues]) => {
                setValues({
                    ...values,
                    [property]: newValue,
                });
            },
        };
    }

    return [
        {
            isSubmitting,
            submit: async () => {
                if (!isSubmitting) {
                    setIsSubmitting(true);

                    await submitHandler();

                    setIsSubmitting(false);
                }
            },
            isValid: getProperties().filter((errorProperty) => errors[errorProperty] !== '').length == 0,
        },
        fields as IVZFormFields<TDefaultValues>,
    ];

    function getErrors(): IVZFormFieldErrors<TDefaultValues> {
        const errors: { [index: string]: string } = {};

        for (const property in values) {
            const value = values[property];
            const error = validateField(property, value);

            errors[property] = error;
        }

        return errors as IVZFormFieldErrors<TDefaultValues>;
    }

    function getProperties(): Extract<keyof TDefaultValues, string>[] {
        return Object.keys(values) as Extract<keyof TDefaultValues, string>[];
    }

    function validateField(property: Extract<keyof TDefaultValues, string>, value: any): string {
        const def = fieldsDefs[property] as IVZFormFieldDef;

        if (def.type !== typeof value) {
            return `${def.type} expected, but got ${typeof value}`;
        }

        if (def.required === true) {
            if (value == null) {
                return 'Required!';
            }

            if (value.toString() === '') {
                return 'Required!';
            }
        }

        if (def.type === 'string') {
            if (def.maxLength != null && value.toString().length > def.maxLength) {
                return `Must not exceed ${def.maxLength} characters!`;
            }

            if (def.minLength != null && value.toString().length < def.minLength) {
                return `Must be at least ${def.minLength} characters!`;
            }
        }

        return '';
    }
}
