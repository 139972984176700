import React, { ReactNode, Fragment } from 'react';

import style from './index.module.scss';
import VZLoadingIndicator from 'dashboard/shared/loading-indicator/index';

interface IVZProps {
    children?: ReactNode;
    isLoading: boolean;
}

export function VZLoadingArea(props: IVZProps) {
    return (
        <Fragment>
            {props.children}
            {props.isLoading && (
                <div className={style.loadingArea}>
                    <VZLoadingIndicator className={style.loadingIndicator} />
                </div>
            )}
        </Fragment>
    );
}
