import React from 'react';
import styled from 'styled-components';

//Shared small button
interface IVZButtonSmallProps {
    imgSrc: string;
    disabledImgSrc?: string;
    disabled: boolean;
    onClick?: () => void;
}
export default function VZButtonSmall(props: IVZButtonSmallProps) {
    const { imgSrc, disabledImgSrc, disabled } = props;
    const imageState = disabled ? (disabledImgSrc ? disabledImgSrc : imgSrc) : imgSrc;

    return (
        <ButtonContainer disabled={disabled}>
            <Image src={imageState} onClick={props.onClick} />
        </ButtonContainer>
    );
}

const ButtonContainer = styled.div<any>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px rgba(151, 151, 151, 0.3);
    background-color: ${({ disabled }) => (disabled ? '#f5f5f6' : '#ffffff')};

    :hover {
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }
`;

const Image = styled.img<any>``;
