import React, { useState, useContext } from 'react';

import { Formik, Field } from 'formik';
import { string, object } from 'yup';
import ReactGA from 'react-ga';

import { Redirect } from 'react-router-dom';

import { authAPI } from 'vzc-client-common';

import style from './index.module.scss';
import { UserContext } from 'vzc-client-common';

interface verificationCodeMatchProp {
    verificationCode?: string;
    userId?: string;
}

interface matchProp {
    params: verificationCodeMatchProp;
}

interface vzVerifyUserComponentProps {
    match: matchProp;
}

type vzVerifyUserComponentState = {
    isAlreadyVerified?: boolean;
    exists?: boolean;
    isNowVerified?: boolean;
    success?: boolean;
};

const vzPasswordSchema = object().shape({
    newPassword: string().min(5, 'Password too short.').required('You must set a new password.'),
    confirmPassword: string().min(5, 'Password too short.').required('Please confirm the password again.'),
});

export default function VZVerifyUser(props: vzVerifyUserComponentProps) {
    const [success, setSuccess] = useState(false);
    const [exists, setExists] = useState(false);
    const [isAlreadyVerified, setIsAlreadyVerified] = useState(false);
    const [isNowVerified, setIsNowVerified] = useState(false);
    const userContext = useContext(UserContext);

    if (props && props.match && props.match.params && props.match.params.userId && props.match.params.verificationCode && !success) {
        ReactGA.pageview('/confirm-email');
        return (
            <div className={style.vzVerifyUserContainer}>
                <div className={style.vzVerifyUserHeader}>
                    <h3 className={style.vzVerifyUserHeaderTitle}>You’re almost done!</h3>
                </div>
                <div className={style.vzVerifyUserBody}>
                    <Formik
                        initialValues={{
                            newPassword: '',
                            confirmPassword: '',
                        }}
                        validationSchema={vzPasswordSchema}
                        onSubmit={async (values, actions) => {
                            if (values.newPassword && values.confirmPassword && values.newPassword === values.confirmPassword) {
                                try {
                                    let setPasswordResponse = await authAPI.authenticate.setPassword(
                                        props.match.params.userId as string,
                                        props.match.params.verificationCode as string,
                                        values.newPassword,
                                        values.confirmPassword
                                    );
                                    if (setPasswordResponse.success) {
                                        userContext.setAuth(setPasswordResponse);

                                        setSuccess(true);
                                    } else {
                                        setSuccess(false);
                                    }

                                    // console.log({
                                    //   userId: this.props.match.params.userId,
                                    //   verificationCode: this.props.match.params.verificationCode,
                                    //   newPassword: values.newPassword,
                                    //   confirmPassword: values.confirmPassword,
                                    // });

                                    actions.setSubmitting(true); // Will set the isSubmitting to false in the event that the POST failed. Won't matter if it is successful. TEMP
                                } catch (err) {
                                    console.log(err);
                                    //Log error
                                    actions.setSubmitting(false);
                                }
                            } else {
                                actions.setStatus({ msg: `Passwords don't match!` });
                                actions.setSubmitting(false); // Will set the isSubmitting to false in the event that the POST failed. Won't matter if it is successful.
                                // log error
                            }
                        }}
                        render={({ errors, status, touched, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <label className={style.vzVerifyUserLabel}>
                                    New Password:
                                    <Field type="password" name="newPassword" className={style.vzVerifyUserTextInput} />
                                    {errors.newPassword && touched.newPassword && (
                                        <div className={style.vzVerifyUserErrorMessage}>{errors.newPassword}</div>
                                    )}
                                </label>
                                <label className={style.vzVerifyUserLabel}>
                                    Confirm Password:
                                    <Field type="password" name="confirmPassword" className={style.vzVerifyUserTextInput} />
                                    {errors.confirmPassword && touched.confirmPassword && (
                                        <div className={style.vzVerifyUserErrorMessage}>{errors.confirmPassword}</div>
                                    )}
                                </label>
                                <input type="submit" value="Set Password" className={style.vzVerifyUserProceedButton} disabled={isSubmitting} />
                                {status && status.msg && <div className={style.vzVerifyUserErrorMessageTotal}>{status.msg}</div>}
                            </form>
                        )}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <Redirect to="/login"></Redirect>
            // <div></div>
        );
    }
}
