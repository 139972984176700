import { VZModal, ToastContext, UserContext } from 'vzc-client-common';
import VZAssignmentTable from 'dashboard/shared/assignment-table';
import IVZTeamMember from 'interfaces/teamMember';
import VZActionButton from 'dashboard/shared/action-button';
import VZLoadingIndicator from 'dashboard/shared/loading-indicator';
import React, { useContext, useState, useEffect } from 'react';
import { TeamContext } from 'dashboard/shared/team-context';
import { authAPI, parseErrorObject } from 'vzc-client-common';
import IVZTeamRole from 'interfaces/teamRole';
import style from './index.module.scss';

interface IVZProps {
    close: () => void;
    onSubmitted: () => void;
    member: IVZTeamMember;
}

export default function VZModifyMemberRolesModal(props: IVZProps) {
    const teamContext = useContext(TeamContext);
    const toastContext = useContext(ToastContext);
    const userContext = useContext(UserContext);
    const [availableRoles, setAvailableRoles] = useState<IVZTeamRole[]>([]);
    const [assignedRoles, setAssignedRoles] = useState<IVZTeamRole[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchRoles();
    }, []);

    return (
        <VZModal title="Modify Member roles" closeModal={props.close}>
            <VZAssignmentTable
                className={style.table}
                availableListTitle="Available Role"
                availableSearchPlaceholder="Search Roles"
                availableList={availableRoles}
                setAvailableList={setAvailableRoles}
                assignedListTitle="Assigned Roles"
                assignedSearchPlaceholder="Search Assigned Roles"
                assignedList={assignedRoles}
                setAssignedList={setAssignedRoles}
                search={search}
                getText={getText}
                getTooltip={getTooltip}
            />
            <VZActionButton label="Save" styleType="ORANGE" disabled={isSubmitting || isLoading} onClick={save} className={style.saveButton} />
            {isSubmitting || (isLoading && <VZLoadingIndicator />)}
        </VZModal>
    );

    async function fetchRoles() {
        if (teamContext.currentTeam && userContext.authToken) {
            setIsLoading(true);
            const roles = await authAPI.roles.getRoles(userContext.authToken, teamContext.currentTeam._id);

            const isRoleInMember = (role: IVZTeamRole) => {
                return props.member.roles.find((r) => r._id === role._id) != null;
            };

            setAvailableRoles(roles.filter((role) => !isRoleInMember(role)));
            setAssignedRoles(roles.filter((role) => isRoleInMember(role)));

            setIsLoading(false);
        }
    }

    function getText(role: IVZTeamRole) {
        return `${role.name}`;
    }

    function getTooltip(role: IVZTeamRole) {
        return `Contains ${role.packages.map((p) => p.name).join(', ')} packages`;
    }

    function search(roles: IVZTeamRole[], search: string) {
        return roles.filter((role) => search === '' || role.name.match(search));
    }

    async function save() {
        if (teamContext.currentTeam && userContext.authToken) {
            try {
                setIsSubmitting(true);

                await authAPI.roles.modifymemberRoles(
                    userContext.authToken,
                    teamContext.currentTeam._id,
                    props.member.id,
                    assignedRoles.map((m) => m._id)
                );

                props.close();
                props.onSubmitted();
            } catch (err) {
                const errObj = parseErrorObject(err);
                toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
            } finally {
                setIsSubmitting(false);
            }
        }
    }
}
