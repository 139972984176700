import React from 'react';

import { NavLink } from 'react-router-dom';

import style from './index.module.scss';

type vzMenuItemProps = {
    route?: string;
    orange?: boolean;
};

type vzMenuItemState = {
    aciveRoute: boolean;
};

export class VzMenuItem extends React.Component<vzMenuItemProps, vzMenuItemState> {
    constructor(props: vzMenuItemProps) {
        super(props);

        this.state = {
            aciveRoute: false,
        };
    }

    render() {
        return (
            <NavLink to={this.props.route ? this.props.route : ''}>
                <div className={this.props.orange ? `${style.vzNavbar_Link} ${style.vzNavOrangeBg}` : style.vzNavbar_Link}>{this.props.children}</div>
            </NavLink>
        );
    }
}

export default VzMenuItem;
