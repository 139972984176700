import { defaultMessages as baseDefaultMessages } from 'vzc-client-common';

export const defaultMessages = Object.assign(
    {
        'dashboard.list.actions': 'ACTIONS',
        'dashboard.list.no_records': 'NO RECORDS FOUND!',

        'dashboard.menu.teams': 'TEAMS',
        'dashboard.menu.company': 'COMPANY',
        'dashboard.menu.profile': 'PROFILE',
        'dashboard.menu.billing': 'BILLING',
        'dashboard.menu.help': 'HELP',
        'dashboard.menu.logout': 'LOGOUT',
        'dashboard.menu.team_selector.member_count': `{count, plural,
    =0 {NO MEMBERS}
    one {# MEMBER}
    other {# MEMBERS}
  }`,
        'dashboard.menu.team_selector.add_a_team': 'Add a Team',
        'dashboard.menu.team_selector.no_team_selected': 'No Team Selected!',
        'dashboard.menu.team_selector.search_placeholder': 'Search for teams',
        'dashboard.menu.team_selector.invite': 'INVITE',
        'dashboard.menu.team_selector.invite.join': 'JOIN',
        'dashboard.menu.team_selector.invite.reject': 'REJECT',
        'dashboard.menu.team_selector.you_joined_the_team_toast': 'You joined the team!',
        'dashboard.menu.team_selector.invite_rejected_toast': 'Invite rejected!',

        'dashboard.teams.no_teams_configured': 'You currently have no teams configured!',
        'dashboard.teams.link_to_add_team': 'Click <a>here</a> to add a team',
        'dashboard.teams.connections': 'CONNECTIONS',
        'dashboard.teams.connections.search': 'Search for Connections',
        'dashboard.teams.connections.count': `{count, plural,
    =0 {NO CONNECTIONS}
    one {# CONNECTION}
    other {# CONNECTIONS}
  }`,
        'dashboard.teams.connections.name': 'CONNECTION NAME',
        'dashboard.teams.connections.short_name': 'SHORT NAME',
        'dashboard.teams.connections.type': 'TYPE',
        'dashboard.teams.delete_connection.title': 'Delete Connection?',
        'dashboard.teams.delete_connection.message': 'Are you sure you want to delete the connection?',
        'dashboard.teams.add_connection': 'ADD CONNECTION',

        'dashboard.teams.members': 'MEMBERS',
        'dashboard.teams.members.count': `{count, plural,
    =0 {NO MEMBERS}
    one {# MEMBER}
    other {# MEMBERS}
  }`,

        'dashboard.teams.invites.count': `{count, plural,
    =0 {NO INVITES}
    one {# INVITE}
    other {# INVITES}
  }`,

        'dashboard.teams.requests.count': `{count, plural,
    =0 {NO REQUESTS}
    one {# REQUEST}
    other {# REQUESTS}
  }`,

        'dashboard.teams.packages.count': `{count, plural,
    =0 {NO PACKAGES}
    one {# PACKAGE}
    other {# PACKAGES}
  }`,

        'dashboard.teams.roles': 'ROLES',
        'dashboard.teams.roles.count': `{count, plural,
    =0 {NO ROLES}
    one {# ROLE}
    other {# ROLES}
  }`,

        'dashboard.teams.manage': 'MANAGE',

        'dashboard.teams.billing': 'BILLING',
        //Company page
        'dashboard.company.members': 'MEMBERS',
        'dashboard.company.roles': 'ROLES',
        'dashboard.company.billing': 'BILLING',
        'dashboard.company.manage': 'MANAGE',
        'dashboard.company.manage_sso': 'MANAGE SSO',
        'dashboard.company.connections': 'CONNECTIONS',
    },
    baseDefaultMessages
);
