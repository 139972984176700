import React, { Component, useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { VZModal, UserContext, useDebounce, ToastContext } from 'vzc-client-common';
import VZSearchBar from 'dashboard/shared/search-bar';
import IVZTeamRole from 'interfaces/teamRole';

import style from './index.module.scss';
import { authAPI } from 'vzc-client-common';
import VZLoadingIndicator from 'dashboard/shared/loading-indicator';
import { TeamContext } from 'dashboard/shared/team-context';
import VZActionButton from 'dashboard/shared/action-button';

import removeImg from './close.svg';
import addImg from './add-grey.svg';
import * as Yup from 'yup';
import { duration } from 'moment';

interface IVZInviteModalProps {
    closeModal?: () => void;
    onSubmitted: () => void;
}

interface IVZInvite {
    email: string;
    roles: IVZTeamRole[];
}

export default function VZInviteModal(props: IVZInviteModalProps) {
    const teamContext = useContext(TeamContext);
    const userContext = useContext(UserContext);
    const toastContext = useContext(ToastContext);

    const [search, setSearch] = useState('');
    const [invites, setInvites] = useState<IVZInvite[]>([]);
    const [isSending, setIsSending] = useState(false);
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const debouncedLoadSuggestions = useDebounce(loadSuggestions, duration(1, 'seconds'));

    useEffect(() => {
        if (search !== '') {
            debouncedLoadSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [search]);

    return (
        <VZModal title="Invite Members" closeModal={props.closeModal}>
            <div className={style.searchContainer}>
                <VZSearchBar
                    searchBarClassName={style.userSearch}
                    search={search}
                    onSearchChanged={setSearch}
                    onSubmit={onSubmitSearch}
                    placeholder="Search for email"
                    isLoading={isLoadingSuggestions}
                    suggestions={suggestions}
                    onSuggestionSelected={onSuggestionSelected}
                />
                <img className={style.add} src={addImg} onClick={onSubmitSearch} />
            </div>

            <div className={style.inviteContainer}>
                <div className={style.inviteScrollContainer}>
                    <div className={style.inviteContentContainer}>{renderInvites()}</div>
                </div>
            </div>

            <div className={style.footer}>
                <VZActionButton styleType="ORANGE" label="Invite" onClick={sendInvites} disabled={invites.length === 0} />
                {isSending && <VZLoadingIndicator />}
            </div>
        </VZModal>
    );

    function renderInvites() {
        return invites.map((invite) => {
            const removeInvite = () => {
                setInvites(invites.filter((inv) => invite.email !== inv.email));
            };

            return (
                <div key={invite.email} className={style.invite}>
                    <img className={style.icon} />

                    <div className={style.email}>{invite.email}</div>

                    <img src={removeImg} className={style.remove} onClick={removeInvite} />
                </div>
            );
        });
    }

    function onSuggestionSelected(suggestion: string) {
        setInvites([...invites, { email: suggestion, roles: [] }]);
        setSearch('');
    }

    async function loadSuggestions() {
        if (!userContext.authToken) {
            return;
        }

        try {
            setIsLoadingSuggestions(true);
            const usersInMyOrg = await authAPI.authenticate.getUsersInMyOrg(userContext.authToken, search);

            setSuggestions(usersInMyOrg.filter((user) => !invites.find((inv) => inv.email === user.email)).map((user) => user.email));
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingSuggestions(false);
        }
    }

    function onSubmitSearch() {
        const check = Yup.string().required().email();

        check.isValid(search).then((isValid) => {
            isValid = isValid && invites.find((inv) => inv.email === search) == null;
            if (isValid) {
                setInvites([...invites, { email: search, roles: [] }]);
                setSearch('');
            }
        });
    }

    async function sendInvites() {
        if (teamContext.currentTeam && userContext.authToken) {
            try {
                setIsSending(true);

                for (let invite of invites) {
                    await authAPI.invites.sendInvite(
                        userContext.authToken,
                        teamContext.currentTeam._id,
                        invite.email,
                        invite.roles.map((r) => r._id)
                    );
                }

                props.onSubmitted();
                toastContext.showToast('Invites sent');
            } catch (err) {
                console.log(err);
            } finally {
                setIsSending(false);
            }

            if (props.closeModal) {
                props.closeModal();
            }
        }
    }
}
