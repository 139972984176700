import React, { Component, useState, useContext } from 'react';
import { connect } from 'react-redux';

import { authAPI, parseErrorObject, IVZCompanyMember } from 'vzc-client-common';

import style from './index.module.scss';
import VZDashboardTable, { useDashboardTable, IVZDashboardTableColumn } from 'dashboard/shared/dashboard-table';
import VZRowButton, { VZRowButtonType } from 'dashboard/shared/row-button';
import { ToastContext, UserContext, VZPrompt, VZUserIcon, defaultMessages } from 'vzc-client-common';
import { TeamContext } from 'dashboard/shared/team-context';
import VZLabelWithEditButton from 'dashboard/shared/label-with-edit-button';
import VZModifyMembershipModal from './modify-membership-modal';

interface IVZMemberListProps {}

export default function VZMemberList(props: IVZMemberListProps) {
    const teamContext = useContext(TeamContext);
    const userContext = useContext(UserContext);

    const [editMember, setEditMember] = useState<IVZCompanyMember | null>(null);
    const [showEditMembershipModal, setShowEditMembershipModal] = useState(false);
    const [showDeleteUserPrompt, setShowDeleteUserPrompt] = useState(false);

    const memberColumns: IVZDashboardTableColumn<IVZCompanyMember>[] = [
        {
            columnId: 'PHOTO',
            label: '',
            width: 60,
            render: (companyMember) => {
                return (
                    <VZUserIcon
                        src={companyMember.iconUrl}
                        className={style.photo}
                        defaultText={companyMember.name.first.substr(0, 1) + companyMember.name.last.substr(0, 1)}
                    />
                );
            },
        },
        {
            columnId: 'NAME',
            label: 'NAME/EMAIL',
            width: 200,
            render: (companyMember) => {
                const nameText = `${companyMember.name.first} ${companyMember.name.last}`;
                const youText = userContext.userId == companyMember.id && ' (YOU)';
                return (
                    <div>
                        <span className={style.name}></span>
                        {nameText}
                        <span className={style.you}>{youText}</span>
                        <br></br>
                        <span className={style.email}>{companyMember.email}</span>
                    </div>
                );
            },
        },
        {
            columnId: 'MEMBERSHIP',
            label: 'MEMBERSHIP',
            width: 200,
            render: (companyMember) => {
                return (
                    <VZLabelWithEditButton
                        label={companyMember.isAdmin ? 'ADMIN' : 'MEMBER'}
                        onEditClick={() => {
                            setShowEditMembershipModal(true);
                            setEditMember(companyMember);
                        }}
                    />
                );
            },
        },
        {
            columnId: 'CONFIRMED',
            label: 'CONFIRMED',
            width: 100,
            render: (companyMember) => {
                return <div>{companyMember.confirmed === true ? 'YES' : 'NO'}</div>;
            },
        },
        {
            columnId: 'ACTIONS',
            label: 'ACTIONS',
            minWidth: 205,
            width: 200,
            flexGrow: 1,
            render: (companyMember, classes) => {
                return (
                    <div className={classes.actions}>
                        <VZRowButton
                            actionType={VZRowButtonType.DELETE}
                            onClick={() => {
                                setShowDeleteUserPrompt(true);
                                setEditMember(companyMember);
                            }}
                        />
                    </div>
                );
            },
        },
    ];
    const tableProps = useDashboardTable(memberColumns, fetchMembers, [userContext.companyId]);
    const toastContext = useContext(ToastContext);

    return (
        <React.Fragment>
            {showDeleteUserPrompt && editMember && (
                <VZPrompt
                    title="Remove Member"
                    message="Are you sure you want to remove this member?"
                    okText="REMOVE"
                    okStyleType="DELETE"
                    onOk={() => removeUserFromCompany(editMember)}
                    cancelText="Cancel"
                    onCancel={() => setShowDeleteUserPrompt(false)}
                />
            )}
            {showEditMembershipModal && editMember && (
                <VZModifyMembershipModal
                    companyMember={editMember}
                    close={() => setShowEditMembershipModal(false)}
                    onSubmitted={tableProps.refresh}
                />
            )}

            <VZDashboardTable<IVZCompanyMember>
                searchBarPlaceholder="Search for Members"
                countSuffix={'dashboard.teams.members.count'}
                {...tableProps}
            />
        </React.Fragment>
    );

    function fetchMembers(search?: string) {
        if (userContext.authToken && userContext.companyId) {
            return authAPI.companies.getCompanyMembers(userContext.authToken, userContext.companyId, search);
        }
        return Promise.resolve([]);
    }

    async function removeUserFromCompany(member: IVZCompanyMember) {
        try {
            if (userContext.authToken && userContext.companyId) {
                await authAPI.companies.deleteUser(userContext.authToken, userContext.companyId, member.id);
            } else {
                throw new Error(`Expected auth and company info`);
            }
        } catch (err) {
            const errorObject = parseErrorObject(err);
            toastContext.showToast(`${errorObject.type}: ${errorObject.message}`, 'ERROR');
        } finally {
            setShowDeleteUserPrompt(false);
            setEditMember(null);

            tableProps.refresh();
        }
    }
}
