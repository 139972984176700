import React, { FormEvent } from 'react';

import axios from 'axios';
import { Formik, Field } from 'formik';
import { string, object, boolean } from 'yup';
import { Redirect, RouteProps } from 'react-router';
import ReactGA from 'react-ga';

import style from './index.module.scss';
import { authAPI, parseErrorObject } from 'vzc-client-common';

type vzSignUpComponentProps = RouteProps & {};

type vzSignUpComponentState = {
    firstName: string;
    lastName: string;
    companyName: string;
    companyEmailAddress: string;
    title?: string;
    phoneNumber?: string;
    agree: boolean;
    registerSuccess: boolean;
    working: boolean;
};

// TODO: Change the bool at the bottom back to referencing EULA instead of 'marketing sgreement'
const vzSignupSchema = object().shape({
    firstName: string()
        .trim()
        .matches(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/, 'Please enter a valid first name.')
        .required('We require your first name.'),
    lastName: string()
        .trim()
        .matches(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/, 'Please enter a valid last name.')
        .required('We require your last name.'),
    companyName: string().min(2, 'This name is too short.').max(50, 'This name is too long.').required('We require your company name.'),
    companyEmailAddress: string()
        .email('Invalid email')
        .matches(
            /\@((?!gmail|outlook|hotmail|yahoo|mail.com|mail.ru|aol|icloud|qq.com|sohu.com|126.com|163.com).)*$/,
            'Please use your company email.'
        )
        .required('We require your work email.'),
    title: string().min(2, 'Too Short').max(50, 'Too Long'),
    phoneNumber: string().matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, 'Phone number is not a valid format.'),
    agree: boolean().oneOf([true], 'You must accept our marketing agreement to proceed.'),
});

export class VzSignUp extends React.Component<vzSignUpComponentProps, vzSignUpComponentState> {
    constructor(props: vzSignUpComponentProps) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            companyName: '',
            companyEmailAddress: '',
            title: '',
            phoneNumber: '',
            agree: false,
            registerSuccess: false,
            working: false,
        };

        this.proceedAccountCreation = this.proceedAccountCreation.bind(this);
    }

    proceedAccountCreation(event: FormEvent) {
        // console.log(this.state);
        event.preventDefault();
    }

    render() {
        if (this.state.registerSuccess === true) {
            return (
                <Redirect
                    to={{
                        pathname: '/signup-confirmation',
                        state: { companyEmailAddress: this.state.companyEmailAddress },
                    }}
                ></Redirect>
            );
        }

        ReactGA.pageview('/signup');

        return (
            <div className={style.vzSignUpContainer}>
                <div className={style.vzSignUpHeader}>
                    <h3 className={style.vzSignUpHeaderTitle}>Get started and take your maintenance team to the next level!</h3>
                </div>
                <div className={style.vzSignUpBody}>
                    <Formik
                        initialValues={this.state}
                        validationSchema={vzSignupSchema}
                        onSubmit={async (values, actions) => {
                            this.setState(values);
                            this.setState({ working: true });
                            actions.setStatus({ msg: 'Signing You Up!' });

                            // Remove spaces from names.
                            const firstName = values.firstName.replace(/\s+/g, '');
                            const lastName = values.lastName.replace(/\s+/g, '');

                            const requestBody = {
                                email: values.companyEmailAddress,
                                name: {
                                    first: firstName.trim(),
                                    last: lastName.trim(),
                                },
                                company: values.companyName,
                                phoneNumber: values.phoneNumber,
                                title: values.title,
                            };

                            try {
                                const response = await authAPI.authenticate.register(requestBody);

                                // if successful redirect to next screen saying to check email
                                ReactGA.event({
                                    category: 'User',
                                    action: 'Created an Account',
                                });

                                this.setState({ registerSuccess: response.result === 'success' });
                            } catch (err) {
                                if (!this.state.registerSuccess) {
                                    const errObj = parseErrorObject(err);
                                    this.setState({ working: false });
                                    actions.setStatus({ msg: errObj.message });
                                    actions.setSubmitting(false);
                                }
                            }
                        }}
                        render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <label className={style.vzSignUpLabel}>
                                    First Name:
                                    <Field type="text" name="firstName" className={style.vzSignUpTextInput} />
                                    {errors.firstName && touched.firstName && <div className={style.vzSignUpErrorMessage}>{errors.firstName}</div>}
                                </label>

                                <label className={style.vzSignUpLabel}>
                                    Last Name:
                                    <Field type="text" name="lastName" className={style.vzSignUpTextInput} />
                                    {errors.lastName && touched.lastName && <div className={style.vzSignUpErrorMessage}>{errors.lastName}</div>}
                                </label>

                                <label className={style.vzSignUpLabel}>
                                    Company Name:
                                    <Field type="text" name="companyName" className={style.vzSignUpTextInput} />
                                    {errors.companyName && touched.companyName && (
                                        <div className={style.vzSignUpErrorMessage}>{errors.companyName}</div>
                                    )}
                                </label>

                                <label className={style.vzSignUpLabel}>
                                    Company Email Address:
                                    <Field type="email" name="companyEmailAddress" className={style.vzSignUpTextInput} />
                                    {errors.companyEmailAddress && touched.companyEmailAddress && (
                                        <div className={style.vzSignUpErrorMessage}>{errors.companyEmailAddress}</div>
                                    )}
                                </label>

                                <label className={style.vzSignUpLabel}>
                                    Title (Optional):
                                    <Field type="text" name="title" className={style.vzSignUpTextInput} />
                                    {errors.title && touched.title && <div className={style.vzSignUpErrorMessage}>{errors.title}</div>}
                                </label>

                                <label className={style.vzSignUpLabel}>
                                    Phone Number (Optional):
                                    <Field type="text" name="phoneNumber" className={style.vzSignUpTextInput} />
                                    {errors.phoneNumber && touched.phoneNumber && (
                                        <div className={style.vzSignUpErrorMessage}>{errors.phoneNumber}</div>
                                    )}
                                </label>

                                <div className={style.vzAgreementsAcceptanceContainer}>
                                    <label>
                                        <input
                                            name="agree"
                                            type="checkbox"
                                            checked={values.agree}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={style.vzAgreementCheckbox}
                                        />
                                    </label>
                                    <span className={style.vzAgreementText}>
                                        By checking this box and pressing the confirm button, you agree to allow us to contact you to keep you updated
                                        on the platform.
                                    </span>
                                </div>
                                {errors.agree && touched.agree && <div className={style.vzSignUpAgreeErrorMessage}>{errors.agree}</div>}
                                {this.state.working === false && (
                                    <input type="submit" value="Proceed" className={style.vzSignUpProceedButton} disabled={isSubmitting} />
                                )}
                                {this.state.working === true && <div className={style.loadingSpinner}></div>}
                                {status && status.msg && <div className={style.vzSignUpErrorMessageTotal}>{status.msg}</div>}
                            </form>
                        )}
                    />
                </div>
            </div>
        );
    }
}

export default VzSignUp;
