import { VZModal, ToastContext, UserContext, IVZCompanyMember } from 'vzc-client-common';
import React, { useState, useContext } from 'react';
import VZActionButton from 'dashboard/shared/action-button';
import VZLoadingIndicator from 'dashboard/shared/loading-indicator';
import { authAPI, combineClassNames } from 'vzc-client-common';
import { TeamContext } from 'dashboard/shared/team-context';
import style from './index.module.scss';
import styled from 'styled-components';
import VZMultiToggle from 'dashboard/shared/multi-toggle-button';

interface IVZProps {
    close: () => void;
    onSubmitted: () => void;
    companyMember: IVZCompanyMember;
}

export default function VZModifyMembershipModal(props: IVZProps) {
    const toastContext = useContext(ToastContext);
    const teamContext = useContext(TeamContext);
    const userContext = useContext(UserContext);

    const [isAdmin, setIsAdmin] = useState(props.companyMember.isAdmin);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const setMembership = (membership: string) => {
        setIsAdmin(membership === 'Admin');
    };

    return (
        <VZModal title="Modify Membership" closeModal={props.close} modalContainerClass={style.modal}>
            {renderTwoOptionToggle(['Admin', 'Member'], isAdmin ? 'Admin' : 'Member', setMembership)}
            <VZActionButton styleType="ORANGE" label="Save" onClick={changeMembership} className={style.saveButton} />
            {isSubmitting && <VZLoadingIndicator />}
        </VZModal>
    );

    function renderTwoOptionToggle(options: string[], value: string, setValue: (newValue: string) => void) {
        return <VZMultiToggle options={options} selectedOption={value} setSelectedOption={setValue} />;
    }

    async function changeMembership() {
        if (userContext.authToken) {
            try {
                setIsSubmitting(true);
                const params = {
                    user: props.companyMember.id,
                    isAdmin: isAdmin,
                };
                await authAPI.companies.changeCompanyMembership(userContext.authToken, userContext.companyId || '', params);

                props.close();
                props.onSubmitted();
            } catch (err) {
                toastContext.showError(err);
            } finally {
                setIsSubmitting(false);
            }
        }
    }
}
