import { TeamContext } from 'dashboard/shared/team-context';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { match } from 'react-router-dom';
import VZConnectedAvatarBar from '../avatar-bar';
import VZNavTabBar from 'dashboard/nav-tab-bar';
import style from './index.module.scss';
import VZPersonalInfoTab from './personal-info-tab';

interface IVZProfilePageProps {
    match: match;
}

export default function VZProfilePage(props: IVZProfilePageProps) {
    const teamContext = useContext(TeamContext);

    return (
        <React.Fragment>
            <div className={style.pageContainer}>
                <div className={style.headerContainer}>
                    <VZConnectedAvatarBar />
                    <h1 className={style.headerTitle}>PROFILE</h1>
                    <VZNavTabBar tabs={getTabs()} />
                </div>
                <Switch>
                    <Redirect exact={true} from={`${props.match.path}/`} to={`${props.match.url}/personalinfo`} />
                    <Route path={`${props.match.path}/personalinfo`} component={VZPersonalInfoTab} />
                </Switch>
            </div>
        </React.Fragment>
    );

    function getTabs() {
        return [{ label: 'PERSONAL INFORMATION', url: `${props.match.url}/personalinfo` }];
    }
}
