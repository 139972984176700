import React, { useState, useContext } from 'react';

import { Formik, Field } from 'formik';
import { string, object } from 'yup';
import ReactGA from 'react-ga';

import { Redirect, Link, match } from 'react-router-dom';

import style from './index.module.scss';
import { UserContext, authAPI } from 'vzc-client-common';
import { DeviceUUID } from 'device-uuid';

const vzLoginSchema = object().shape({
    newPassword: string().min(5, 'Password too short.').max(25, 'Password too long').required('Required.'),
    confirmPassword: string().min(5, 'Password too short.').max(25, 'Password too long.').required('Required.'),
});

export default function VZResetPassword(props: { match: match<{ id: string; verificationCode: string }> }) {
    const [success, setSuccess] = useState(false);
    const userContext = useContext(UserContext);

    if (!success) {
        ReactGA.pageview('/login');
        return (
            <div className={style.vzLoginContainer}>
                <div className={style.vzLoginHeader}>
                    <h3 className={style.vzLoginHeaderTitle}>Reset Password</h3>
                </div>
                <div className={style.vzLoginBody}>
                    <Formik
                        initialValues={{
                            newPassword: '',
                            confirmPassword: '',
                        }}
                        validationSchema={vzLoginSchema}
                        onSubmit={async (values, actions) => {
                            try {
                                if (!values.newPassword || !values.confirmPassword) {
                                    throw new Error('Password required!');
                                }

                                if (values.newPassword !== values.confirmPassword) {
                                    throw new Error('Passwords must match!');
                                }

                                const deviceUUID = new DeviceUUID().get();
                                let loginResponse = await authAPI.authenticate.resetPassword(
                                    props.match.params.id,
                                    props.match.params.verificationCode,
                                    values.newPassword
                                );

                                setSuccess(true);
                                actions.setStatus({ msg: '' });
                            } catch (err) {
                                console.log(err);
                                //Log error
                                actions.setStatus({ msg: JSON.stringify(err) });
                            } finally {
                                actions.setSubmitting(false);
                            }
                        }}
                        render={({ errors, status, touched, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <label className={style.vzLogInLabel}>
                                    New Password:
                                    <Field type="password" name="newPassword" className={style.vzLogInTextInput} />
                                    {errors.newPassword && touched.newPassword && (
                                        <div className={style.vzLogInErrorMessage}>{errors.newPassword}</div>
                                    )}
                                </label>

                                <label className={style.vzLogInLabel}>
                                    Confirm Password:
                                    <Field type="password" name="confirmPassword" className={style.vzLogInTextInput} />
                                    {errors.confirmPassword && touched.confirmPassword && (
                                        <div className={style.vzLogInErrorMessage}>{errors.confirmPassword}</div>
                                    )}
                                </label>

                                <input type="submit" value="Proceed" className={style.vzLogInProceedButton} disabled={isSubmitting} />
                                {status && status.msg && <div className={style.vzLogInErrorMessageTotal}>{status.msg}</div>}
                            </form>
                        )}
                    />
                </div>
            </div>
        );
    } else {
        return <Redirect to="/login"></Redirect>;
    }
}
