import { TeamContext } from 'dashboard/shared/team-context';
import React, { useContext, useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { match } from 'react-router-dom';
import VZConnectedAvatarBar from '../avatar-bar';
import VZNavTabBar from '../nav-tab-bar';
import style from './index.module.scss';
import { UserContext, VZUserIcon, useLocale, ToastContext, authAPI, IVZCompany } from 'vzc-client-common';
import VZManageTab from './manage-connection-tab';
import VZCompanyMembersTab from './manage-members-tab';

export default function VZCompanyPage(props: { match: match }) {
    const locale = useLocale();
    const userContext = useContext(UserContext);
    const toastContext = useContext(ToastContext);

    const [company, setCompany] = useState<IVZCompany | null>(null);

    useEffect(() => {
        loadCompany();
    }, []);

    return (
        <Fragment>
            <div className={style.pageContainer}>
                <div className={style.headerContainer}>
                    <VZConnectedAvatarBar />
                    <h1 className={style.headerTitle}>{locale.formatMessage('dashboard.menu.company')}</h1>
                    {company && (
                        <>
                            <div className={style.teamInfo}>
                                <VZUserIcon className={style.teamImg} src={undefined} defaultText={company.name.substr(0, 2)} />
                                <div className={style.currentTeam}>
                                    {company.name} <span className={style.companyId}>({company._id})</span>
                                </div>
                            </div>
                            <div className={style.domain}>{company.emailDomains.join('')}</div>
                        </>
                    )}
                    <VZNavTabBar tabs={getTabs()} />
                </div>
                <Switch>
                    <Redirect exact={true} from={`${props.match.path}/`} to={`${props.match.url}/manage-connection`} />
                    <Route path={`${props.match.path}/manage-connection`} component={VZManageTab} />
                    <Route path={`${props.match.path}/members`} component={VZCompanyMembersTab} />
                    <Route path={`${props.match.path}/billing`} />
                    <Route path={`${props.match.path}/manage`} />
                </Switch>
            </div>
        </Fragment>
    );

    function getTabs() {
        return [
            { label: locale.formatMessage('dashboard.company.manage_sso'), url: `${props.match.url}/manage-connection` },
            { label: locale.formatMessage('dashboard.company.members'), url: `${props.match.url}/members` },
            // { label: locale.formatMessage('dashboard.company.billing'), url: `${props.match.url}/billing` },
            // { label: locale.formatMessage('dashboard.company.manage'), url: `${props.match.url}/manage` },
        ];
    }

    async function loadCompany() {
        try {
            if (userContext.authToken == null) {
                throw new Error(`Expected an Auth Token!`);
            }
            if (userContext.companyId == null) {
                throw new Error('Expected company id!');
            }

            const newCompany = await authAPI.companies.getCompany(userContext.authToken, userContext.companyId);
            setCompany(newCompany);
        } catch (err) {
            toastContext.showError(err);
        }
    }
}
