import React, { Component, useState, useContext } from 'react';
import { connect } from 'react-redux';

import IVZTeamMember from 'interfaces/teamMember';
import { authAPI, parseErrorObject } from 'vzc-client-common';

import style from './index.module.scss';
import VZDashboardTable, { useDashboardTable, IVZDashboardTableColumn } from 'dashboard/shared/dashboard-table';
import VZRowButton, { VZRowButtonType } from 'dashboard/shared/row-button';
import { ToastContext, UserContext, VZPrompt, VZUserIcon, defaultMessages } from 'vzc-client-common';
import { TeamContext } from 'dashboard/shared/team-context';
import VZLabelWithEditButton from 'dashboard/shared/label-with-edit-button';
import VZModifyMembershipModal from './modify-membership-modal';
import VZModifyMemberRolesModal from './modify-member-roles-modal';
import { VZBillingType } from 'interfaces/team';

interface IVZMemberListProps {}

export default function VZMemberList(props: IVZMemberListProps) {
    const teamContext = useContext(TeamContext);
    const userContext = useContext(UserContext);

    const [editMember, setEditMember] = useState<IVZTeamMember | null>(null);
    const [showEditMembershipModal, setShowEditMembershipModal] = useState(false);
    const [showEditMemberRolesModal, setShowEditMemberRolesModal] = useState(false);
    const [showDeleteUserPrompt, setShowDeleteUserPrompt] = useState(false);

    const memberColumns: IVZDashboardTableColumn<IVZTeamMember>[] = [
        {
            columnId: 'PHOTO',
            label: '',
            width: 60,
            render: (teamMember) => {
                return (
                    <VZUserIcon
                        src={teamMember.iconUrl}
                        className={style.photo}
                        defaultText={teamMember.name.first.substr(0, 1) + teamMember.name.last.substr(0, 1)}
                    />
                );
            },
        },
        {
            columnId: 'NAME',
            label: 'NAME/EMAIL',
            width: 200,
            render: (teamMember) => {
                const nameText = `${teamMember.name.first} ${teamMember.name.last}`;
                const youText = userContext.userId == teamMember.id && ' (YOU)';
                return (
                    <div>
                        <span className={style.name}></span>
                        {nameText}
                        <span className={style.you}>{youText}</span>
                        <br></br>
                        <span className={style.email}>{teamMember.email}</span>
                    </div>
                );
            },
        },
        {
            columnId: 'MEMBERSHIP',
            label: 'MEMBERSHIP',
            width: 200,
            render: (teamMember) => {
                return (
                    <VZLabelWithEditButton
                        label={teamMember.isAdmin ? 'ADMIN' : 'MEMBER'}
                        onEditClick={() => {
                            setShowEditMembershipModal(true);
                            setEditMember(teamMember);
                        }}
                    />
                );
            },
        },
        {
            columnId: 'ROLES',
            label: 'ROLES',
            width: 200,
            render: (teamMember) => {
                return (
                    <VZLabelWithEditButton
                        label={teamMember.roles.map((r) => r.name).join(',')}
                        onEditClick={() => {
                            setShowEditMemberRolesModal(true);
                            setEditMember(teamMember);
                        }}
                    />
                );
            },
        },
        getVariableColumn(),
        {
            columnId: 'ACTIONS',
            label: 'ACTIONS',
            minWidth: 205,
            width: 200,
            flexGrow: 1,
            render: (teamMember, classes) => {
                return (
                    <div className={classes.actions}>
                        {true && (
                            <VZRowButton
                                actionType={VZRowButtonType.DELETE}
                                onClick={() => {
                                    setShowDeleteUserPrompt(true);
                                    setEditMember(teamMember);
                                }}
                            />
                        )}
                    </div>
                );
            },
        },
    ];
    const tableProps = useDashboardTable(memberColumns, fetchMembers, [teamContext.currentTeam]);
    const toastContext = useContext(ToastContext);

    return (
        <React.Fragment>
            {showDeleteUserPrompt && editMember && (
                <VZPrompt
                    title="Remove User"
                    message="Are you sure you want to remove this user?"
                    okText="REMOVE"
                    okStyleType="DELETE"
                    onOk={() => removeUserFromTeam(editMember)}
                    cancelText="Cancel"
                    onCancel={() => setShowDeleteUserPrompt(false)}
                />
            )}
            {showEditMembershipModal && editMember && (
                <VZModifyMembershipModal teamMember={editMember} close={() => setShowEditMembershipModal(false)} onSubmitted={tableProps.refresh} />
            )}
            {showEditMemberRolesModal && editMember && (
                <VZModifyMemberRolesModal member={editMember} close={() => setShowEditMemberRolesModal(false)} onSubmitted={tableProps.refresh} />
            )}

            <VZDashboardTable<IVZTeamMember>
                searchBarPlaceholder="Search for Members"
                countSuffix={'dashboard.teams.members.count'}
                {...tableProps}
            />
        </React.Fragment>
    );

    function fetchMembers(search?: string) {
        if (teamContext.currentTeam && userContext.authToken) {
            return authAPI.teams.getMembers(userContext.authToken, teamContext.currentTeam._id, search);
        }
        return Promise.resolve([]);
    }

    async function removeUserFromTeam(member: IVZTeamMember) {
        try {
            if (teamContext.currentTeam && userContext.authToken) {
                await authAPI.teams.removeUserFromTeam(userContext.authToken, teamContext.currentTeam._id, member.id);
            }

            tableProps.refresh();

            toastContext.showToast('User removed!');
            setShowDeleteUserPrompt(false);
        } catch (err) {
            const errorObject = parseErrorObject(err);
            toastContext.showToast(`${errorObject.type}: ${errorObject.message}`, 'ERROR');
        }
    }

    // Returns cost/packages column based on team billing type.
    function getVariableColumn(): IVZDashboardTableColumn<IVZTeamMember> {
        if (teamContext.currentTeam && teamContext.currentTeam.billingType === VZBillingType.DYNAMIC) {
            return {
                columnId: 'COST',
                label: 'COST',
                width: 150,
                render: (teamMember) => {
                    return <div className={style.cost}>${teamMember.costPerMonth}/mo</div>;
                },
            };
        } else {
            return {
                columnId: 'PACKAGES',
                label: 'PACKAGES',
                width: 150,
                render: (teamMember) => {
                    return <div className={style.cost}>{teamMember.packages.length} packages</div>;
                },
            };
        }
    }
}
