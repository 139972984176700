import React, { Component, useContext } from 'react';

import style from './index.module.scss';
import { connect } from 'react-redux';

import alertsIcon from './alerts-header.svg';
import mailIcon from './mail-header.svg';
import teamsIcon from './teams-header.svg';
import { combineClassNames } from 'vzc-client-common';
import { UserContext, VZUserIcon } from 'vzc-client-common';

interface IVZAvatarBarProps {}

export default function VZAvatarBar() {
    const userContext = useContext(UserContext);

    return (
        <div className={style.container}>
            <VZUserIcon
                className={style.avatarIcon}
                src={userContext.iconUrl}
                defaultText={userContext.firstName.substr(0, 1) + userContext.lastName.substr(0, 1)}
            />
            <p className={style.userName}>
                {userContext.firstName} {userContext.lastName}
            </p>
            <img className={combineClassNames(style.img, style.firstImg)} src={teamsIcon} />
            <img className={style.img} src={alertsIcon} />
            <img className={style.img} src={mailIcon} />
        </div>
    );
}
