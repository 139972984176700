import React, { ReactNode, useState, useEffect } from 'react';
import { Table, Column, TableCellProps, AutoSizer, Size, TableHeaderRowProps, Index } from 'react-virtualized';
import VZSearchBar from 'dashboard/shared/search-bar';

import style from './index.module.scss';
import standardStyle from './standard.module.scss';
import { combineClassNames } from 'vzc-client-common';
import { useLocale, VZTranslationKey } from 'vzc-client-common';
import VZLoadingIndicator from '../loading-indicator';

// Stanard classes.
const standardDashboardTableClasses = {
    actions: standardStyle.actions,
    nameLabel: standardStyle.nameLabel,
};

type VZStandardDashboardTableClasses = typeof standardDashboardTableClasses;

export interface IVZDashboardTableColumn<TRow> {
    columnId: string;
    label: string;
    render: (row: TRow, classes: VZStandardDashboardTableClasses) => ReactNode;

    width: number;
    minWidth?: number;
    maxWidth?: number;
    flexGrow?: number;
    flexShrink?: number;
}

// Base props for dashboard table, returned from useDashboardTable()
interface IVZDashboardTableBaseProps<TRow> {
    columns: IVZDashboardTableColumn<TRow>[];
    search: string;
    rows: TRow[];
    isRefreshing: boolean;
    error: string | null;

    refresh: () => void;
    setSearch: (newSearch: string) => void;
}

export interface IVZDashboardTableProps<TRow> extends IVZDashboardTableBaseProps<TRow> {
    masterClassName?: string;
    searchBarPlaceholder?: string;
    countSuffix: VZTranslationKey;
}

// Called to create a props object that can be passed to component VZDashboardTable
export function useDashboardTable<TRow>(
    columns: IVZDashboardTableColumn<TRow>[],
    refreshFunc: (search: string) => Promise<TRow[]>,
    dependencies?: any[]
): IVZDashboardTableBaseProps<TRow> {
    const [rows, setRows] = useState<TRow[]>([]);
    const [search, setSearch] = useState('');
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [refreshIndicator, setRefreshIndicator] = useState(0);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        refreshTableData();
    }, [refreshIndicator, ...(dependencies || [])]);

    return {
        search,
        rows,
        isRefreshing,
        error,
        columns,

        // Force refresh using the useEffect() above.
        refresh: () => setRefreshIndicator(refreshIndicator + 1),
        setSearch,
    };

    async function refreshTableData() {
        if (isRefreshing) {
            return;
        }

        try {
            console.log('REFRESH');
            setRows([]);
            setError(null);
            setIsRefreshing(true);

            console.log(`SEARCH with ${search}`);
            const rows: TRow[] = await refreshFunc(search);

            setRows(rows);
        } catch (err) {
            console.log(err);
            setError(err);
        } finally {
            setIsRefreshing(false);
        }
    }
}

/** Renders a table.
 */
export default function VZDashboardTable<TRow>(props: IVZDashboardTableProps<TRow>) {
    const locale = useLocale();

    const columns = props.columns.map((inColumn) => (
        <Column
            key={inColumn.columnId}
            dataKey={inColumn.columnId}
            label={inColumn.label}
            width={inColumn.width}
            minWidth={inColumn.minWidth}
            maxWidth={inColumn.maxWidth}
            flexGrow={inColumn.flexGrow}
            flexShrink={inColumn.flexShrink}
            // Pass standard table classes so implementers can use them (standardizes styling)
            cellRenderer={(props: TableCellProps) => inColumn.render(props.rowData, standardDashboardTableClasses)}
        />
    ));

    return (
        <div className={combineClassNames(style.masterContainer, props.masterClassName)}>
            <div className={style.header}>
                <VZSearchBar
                    placeholder={props.searchBarPlaceholder}
                    searchBarClassName={style.searchBar}
                    loadingClassName={style.loadingIndicator}
                    search={props.search}
                    onSearchChanged={props.setSearch}
                    onSubmit={props.refresh}
                />
                <div className={style.miscContainer}>
                    <div className={style.countLabel}>{locale.formatMessage(props.countSuffix, { count: props.rows.length })}</div>
                </div>
            </div>
            <div className={style.tableContainer}>
                <AutoSizer>{renderTable}</AutoSizer>
            </div>
        </div>
    );

    function renderTable(size: Size) {
        return (
            <Table
                width={size.width}
                height={size.height}
                rowHeight={80}
                headerHeight={40}
                className={style.noOutline}
                gridClassName={style.noOutline}
                rowCount={props.rows.length}
                rowGetter={(index) => props.rows[index.index]}
                headerClassName={style.columnHeader}
                rowClassName={getRowClassName}
                headerRowRenderer={renderHeader}
                headerStyle={{ backgroundColor: '#ffffff' }}
                noRowsRenderer={renderNoRows}
                noContentRenderer={renderNoRows}
            >
                {columns}
            </Table>
        );
    }

    // Returns a class name based on the row.
    function getRowClassName(index: Index) {
        // -1 is header.
        if (index.index === -1) {
            return '';
        } else {
            return style.row;
        }
    }

    function renderHeader(props: TableHeaderRowProps) {
        return (
            <div className={combineClassNames(props.className, style.tableHeader)} role="row" style={props.style}>
                {props.columns}
            </div>
        );
    }

    function renderNoRows() {
        if (props.isRefreshing) {
            return (
                <div className={style.loadingRegion}>
                    <VZLoadingIndicator className={style.loading} />
                </div>
            );
        }
        return <p className={style.noRecordsMessage}>{locale.formatMessage('dashboard.list.no_records')}</p>;
    }
}
