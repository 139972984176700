import React, { useState, useContext, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import style from './index.module.scss';
import {
    authAPI,
    VZInput,
    ToastContext,
    UserContext,
    VZActionButton,
    parseErrorObject,
    VZCompanyAuthType,
    IVZCompanyAuth,
    useLocale,
} from 'vzc-client-common';
import VZBox from 'dashboard/shared/box';
import { TeamContext } from 'dashboard/shared/team-context';
import { VZLoadingArea } from 'dashboard/shared/loading-area';
import moment from 'moment';

import readyImg from './ready.svg';
import { VZPrompt } from 'shared/VZPrompt';

export default function VZManageTab() {
    const userContext = useContext(UserContext);
    const locale = useLocale();
    const toastContext = useContext(ToastContext);
    const [showCreateServiceMetaPrompt, setShowCreateServiceMetaPrompt] = useState(false);
    const [adfsUrl, setAdfsUrl] = useState('');
    const [clientId, setClientId] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [companyAuth, setCompanyAuth] = useState<IVZCompanyAuth>({
        authType: VZCompanyAuthType.BASIC,
        isIdentityProviderCertSetup: false,
        isServiceProviderMetadataSetup: false,
        serviceProviderMetadataExpireDate: null,
    });
    const [isLoading, setIsLoading] = useState(false);

    const toggleSSO = companyAuth != null && companyAuth.authType === VZCompanyAuthType.SSO;
    const certExpireDate = companyAuth.serviceProviderMetadataExpireDate
        ? moment(companyAuth.serviceProviderMetadataExpireDate).format('YYYY-MM-DD HH:mm:ss')
        : '';

    useEffect(() => {
        loadCompanyAuth();
        loadPrintApplicationCredentials();
    }, []);

    return (
        <div className={style.tab}>
            <VZLoadingArea isLoading={isLoading}>
                <div className={style.headerContainer}></div>
                {showCreateServiceMetaPrompt && renderCreateServiceMetaPrompt()}
                <VZBox title="SERVICE PROVIDER" toggleTitle="SSO ENABLED" toggleValue={toggleSSO} onToggleClick={() => handleSSOToggle()}>
                    {companyAuth.isServiceProviderMetadataSetup && (
                        <ControlContainer>
                            <ReadyIcon src={readyImg} />
                            <InputLabel disabled={false}>SP Metadata generated, Cert expires: {certExpireDate}</InputLabel>
                        </ControlContainer>
                    )}

                    <ControlContainer>
                        <InputLabel disabled={false}>Generate new SP Certificate and Metadata</InputLabel>
                        <VZActionButton styleType="ORANGE" label="Generate" onClick={() => setShowCreateServiceMetaPrompt(true)} disabled={false} />
                    </ControlContainer>
                    <ControlContainer>
                        <InputLabel disabled={!companyAuth.isServiceProviderMetadataSetup}>Download Service Provider Metadata</InputLabel>
                        <VZActionButton
                            styleType="ORANGE"
                            label="Download"
                            onClick={() => downloadServiceMetaData()}
                            disabled={!companyAuth.isServiceProviderMetadataSetup}
                        />
                    </ControlContainer>
                </VZBox>
                <VZBox title="IDENTITY PROVIDER">
                    {companyAuth.isIdentityProviderCertSetup && (
                        <ControlContainer>
                            <ReadyIcon src={readyImg} />
                            <InputLabel disabled={false}>Identity Provider Cert Imported</InputLabel>
                        </ControlContainer>
                    )}

                    <ControlContainer>
                        <InputLabel disabled={false}>IDP Metadata URL</InputLabel>
                        <VZInput
                            className={style.formTextInput}
                            value={adfsUrl}
                            setValue={setAdfsUrl}
                            placeholder="Paste URL Here"
                            disabled={false}
                        />
                    </ControlContainer>
                    <ControlContainer>
                        <InputLabel disabled={false}></InputLabel>
                        <VZActionButton styleType="ORANGE" label="Import" onClick={() => retreiveADFSCertificate(adfsUrl)} disabled={adfsUrl == ''} />
                    </ControlContainer>
                </VZBox>
                <VZBox title="Print Application Credentials Config">
                    <ControlContainer>
                        <InputLabel disabled={false}>Client Id</InputLabel>
                        <VZInput
                            className={style.formTextInput}
                            value={clientId}
                            setValue={setClientId}
                            placeholder="Paste client id here"
                            disabled={false}
                        />
                    </ControlContainer>
                    <ControlContainer>
                        <InputLabel disabled={false}>Client Secret Key</InputLabel>
                        <VZInput
                            className={style.formTextInput}
                            value={secretKey}
                            setValue={setSecretKey}
                            placeholder="Paste secret key here"
                            disabled={false}
                        />
                    </ControlContainer>
                    <ControlContainer>
                        <InputLabel disabled={false}></InputLabel>
                        <VZActionButton styleType="ORANGE" label="Save" onClick={() => savePrintConfig(secretKey, clientId)} disabled={secretKey == '' || clientId == ''} />
                    </ControlContainer>
                </VZBox>
            </VZLoadingArea>
        </div>
    );

    async function handleSSOToggle() {
        // setToggleSSO(!toggleSSO);

        // if(companyAuth != null) {
        //   setCompanyAuth({
        //     ...companyAuth,
        //     authType: toggleSSO ? VZCompanyAuthType.BASIC : VZCompanyAuthType.SSO
        //   });
        // }

        await updateCompanyAuth({ authType: toggleSSO ? VZCompanyAuthType.BASIC : VZCompanyAuthType.SSO });
    }

    async function retreiveADFSCertificate(adfsUrl: string) {
        if (!userContext.authToken || userContext.companyId == null) {
            return;
        }

        try {
            const params = {
                adfsUrl,
            };
            //TODO: Save base64 string returned
            await authAPI.authenticate.retrieveADFSCertificate(userContext.authToken, userContext.companyId, params);

            toastContext.showToast('Downloaded ADFS Cert', 'SUCCESS');

            loadCompanyAuth();
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
        }
    }

    async function savePrintConfig(clientSecret: string, clientId: string) {
        if (!userContext.authToken || userContext.companyId == null) {
            return;
        }

        try {
            await authAPI.appCredentials.createAppCredentials(userContext.authToken, clientId, clientSecret);

            toastContext.showToast('Saved print application credentials', 'SUCCESS');
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
        }
    }

    function renderCreateServiceMetaPrompt() {
        return (
            <VZPrompt
                title="Create new service metadata?"
                message="Are you sure you want to generate a new SP Certificate and Metadata?"
                okText={locale.formatMessage('prompt.yes')}
                onOk={() => createServiceMetaData()}
                onClose={() => setShowCreateServiceMetaPrompt(false)}
                cancelText={locale.formatMessage('prompt.no')}
            />
        );
    }

    async function createServiceMetaData() {
        if (!userContext.authToken || userContext.companyId == null) {
            return;
        }

        try {
            await authAPI.authenticate.createServiceProviderMetaData(userContext.authToken, userContext.companyId);
            toastContext.showToast('Generated SP & Metadata', 'SUCCESS');

            loadCompanyAuth();
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
        }
    }

    async function downloadServiceMetaData() {
        if (!userContext.authToken || userContext.companyId == null) {
            return;
        }

        try {
            const xmlStr = await authAPI.authenticate.downloadServiceProviderMetaData(userContext.authToken, userContext.companyId);
            downloadFile('ServiceProviderMetadata', xmlStr, 'text/xml');
            toastContext.showToast('Downloaded Service Provider Metadata', 'SUCCESS');
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
        }
    }

    function downloadFile(fileName: string, fileStr: string, fileType: string) {
        let a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(new File([fileStr], fileName, { type: fileType }));
        a.click();
    }

    async function updateCompanyAuth(updateParams: { identityProviderBaseUrl?: string; authType?: VZCompanyAuthType }) {
        if (!userContext.authToken || userContext.companyId == null) {
            return;
        }
        try {
            await authAPI.companies.updateCompanyAuth(userContext.authToken, userContext.companyId, updateParams);

            loadCompanyAuth();

            toastContext.showToast('Company Authentication Updated', 'SUCCESS');
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
            console.error(errObj);
        } finally {
        }
    }

    async function loadCompanyAuth() {
        if (!userContext.authToken || userContext.companyId == null) {
            return;
        }

        setIsLoading(true);

        try {
            const newCompanyAuth = await authAPI.companies.getCompanyAuth(userContext.authToken, userContext.companyId);
            setCompanyAuth(newCompanyAuth);
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
            console.error(errObj);
        } finally {
            setIsLoading(false);
        }
    }

    async function loadPrintApplicationCredentials() {
        if (!userContext.authToken || userContext.companyId == null) {
            return;
        }

        setIsLoading(true);

        try {
            const appCreds = await authAPI.appCredentials.getAppCredentials(userContext.authToken);
            setSecretKey(appCreds.clientSecret);
            setClientId(appCreds.clientId);
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
            console.error(errObj);
        } finally {
            setIsLoading(false);
        }
    }
}

const ControlContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 10px 0;
`;
const InputLabel = styled.span`
    flex-grow: 1;
    font-size: 12px;
    font-weight: 500;
    color: ${({ disabled }) => (!disabled ? '#555555' : '#afafaf')};
    text-transform: uppercase;
`;

const ReadyIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 15px;
`;
