import React, { ReactNode } from 'react';
import styled from 'styled-components';
import VZToggle from '../toggle';

//Box component
interface IVZBoxProps {
    title: string;
    toggleValue?: boolean;
    onToggleClick?: () => void;
    toggleTitle?: string;
    children: ReactNode;
}
export default function VZBox(props: IVZBoxProps) {
    const { toggleValue, toggleTitle, onToggleClick, title, children } = props;
    return (
        <BoxContainer>
            <BoxHeader>
                <Title>{title}</Title>
                {toggleTitle != undefined && <ToggleTitle>{toggleTitle}</ToggleTitle>}
                {toggleValue != undefined && onToggleClick != undefined && <VZToggle toggleValue={toggleValue} onToggleClick={onToggleClick} />}
            </BoxHeader>
            <BoxContent>{children}</BoxContent>
        </BoxContainer>
    );
}

const BoxContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    height: auto;
    width: auto;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    margin-bottom: 20px;
`;
const BoxHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    padding: 0 10px;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
`;
const Title = styled.span`
    flex-grow: 1;
    color: #555555;
    font-size: 14px;
    font-weight: 600;
`;
const BoxContent = styled.div`
    position: relative;
    flex: 1;
    overflow: auto;
    padding: 15px;
    background-color: #f5f5f6;
`;
const ToggleTitle = styled.span`
    color: #555555;
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
`;
