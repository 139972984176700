import React, { useContext } from 'react';

import { authAPI } from 'vzc-client-common';

import style from './index.module.scss';
import VZDashboardTable, { useDashboardTable, IVZDashboardTableColumn } from 'dashboard/shared/dashboard-table';
import IVZTeamInvite from 'interfaces/teamInvite';
import { TeamContext } from 'dashboard/shared/team-context';
import { UserContext } from 'vzc-client-common';

interface IVZInviteListProps {
    dependency?: any;
}

export default function VZInviteList(props: IVZInviteListProps) {
    const teamContext = useContext(TeamContext);
    const userContext = useContext(UserContext);

    const inviteColumns: IVZDashboardTableColumn<IVZTeamInvite>[] = [
        {
            columnId: 'PHOTO',
            label: '',
            width: 60,
            render: (invite) => {
                return <img src={invite.invitedUser.iconUrl} className={style.photo}></img>;
            },
        },
        {
            columnId: 'NAME',
            label: 'NAME/EMAIL',
            width: 200,
            render: (invite) => {
                return (
                    <div>
                        <span className={style.name}>
                            {invite.invitedUser.name.first} {invite.invitedUser.name.last}
                        </span>
                        <br></br>
                        <span className={style.email}>{invite.invitedUser.email}</span>
                    </div>
                );
            },
        },
        {
            columnId: 'INVITED_ON',
            label: 'INVITED ON',
            width: 200,
            render: (invite) => {
                return <div>{invite.createdDate}</div>;
            },
        },
        {
            columnId: 'ACTIONS',
            label: 'ACTIONS',
            minWidth: 205,
            width: 200,
            flexGrow: 1,
            render: (teamMember, classes) => {
                return <div className={classes.actions}></div>;
            },
        },
    ];
    const tableProps = useDashboardTable(inviteColumns, fetchInvites, [teamContext.currentTeam, props.dependency]);

    return (
        <VZDashboardTable<IVZTeamInvite> searchBarPlaceholder="Search for Invites" countSuffix={'dashboard.teams.invites.count'} {...tableProps} />
    );

    function fetchInvites(search?: string) {
        if (teamContext.currentTeam && userContext.authToken) {
            return authAPI.invites.getInvitesToTeam(userContext.authToken, teamContext.currentTeam._id, search);
        }
        return Promise.resolve([]);
    }
}
