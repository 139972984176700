export default interface IVZTeam {
    _id: string;
    name: string;
    company: string;
    domain: string;
    owner: string;
    users: Array<{ user: string; isAdmin: boolean; permissions: string[] }>;
    iconUrl: string;
}

export interface IVZJoinableTeam extends IVZTeam {
    hasRequestedAccess: boolean;
}

export enum VZBillingType {
    DYNAMIC = 'DYNAMIC',
    PO = 'PO',
}

export interface IVZMyTeam extends IVZTeam {
    isUserAdmin: boolean;
    billingType: VZBillingType;
}
