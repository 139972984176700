import React, { useContext, useEffect, Fragment, useState } from 'react';
import styled from 'styled-components';
import { VZModal, UserContext, ToastContext, combineClassNames, authAPI } from 'vzc-client-common';
import * as Yup from 'yup';
import { TeamContext } from 'dashboard/shared/team-context';
import VZActionButton from 'dashboard/shared/action-button';
import { Formik } from 'formik';
import style from './index.module.scss';
import { VZLoadingArea } from 'dashboard/shared/loading-area';

interface IVZInviteModalProps {
    handleCloseModal: () => void;
    onSubmitted: () => void;
}

export default function VZInviteModal(props: IVZInviteModalProps) {
    const teamContext = useContext(TeamContext);
    const userContext = useContext(UserContext);
    const [isSending, setIsSending] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const toastContext = useContext(ToastContext);

    return (
        <VZModal title="Invite Members" closeModal={props.handleCloseModal}>
            <Formik
                initialValues={{ firstName: '', lastName: '', email: '' }}
                onSubmit={({ firstName, lastName, email }) => {
                    setErrorMsg('');
                    inviteUser(firstName, lastName, email);
                }}
                validationSchema={Yup.object().shape({
                    firstName: Yup.string().required('Required'),
                    lastName: Yup.string().required('Required'),
                    email: Yup.string().required('Required').email('Must be a valid email!'),
                })}
            >
                {(formProps) => {
                    const { isValid, values, errors, touched, handleSubmit, setFieldValue, handleChange, isSubmitting, handleBlur } = formProps;
                    return (
                        <Fragment>
                            <VZLoadingArea isLoading={isSending}>
                                <label className={style.formLabel}>First Name</label>
                                <input
                                    id="firstName"
                                    type="text"
                                    className={combineClassNames(style.formTextInput, errors.firstName && touched.firstName ? style.error : null)}
                                    placeholder="Enter First Name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.firstName && touched.firstName && <p className={style.formError}>{errors.firstName}</p>}

                                <label className={style.formLabel}>Last Name</label>
                                <input
                                    id="lastName"
                                    type="text"
                                    className={combineClassNames(style.formTextInput, errors.lastName && touched.lastName ? style.error : null)}
                                    placeholder="Enter Last Name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.lastName && touched.lastName && <p className={style.formError}>{errors.lastName}</p>}

                                <label className={style.formLabel}>Email</label>
                                <input
                                    id="email"
                                    type="text"
                                    className={combineClassNames(style.formTextInput, errors.email && touched.email ? style.error : null)}
                                    placeholder="Enter Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.email && touched.email && <p className={style.formError}>{errors.email}</p>}
                                <Button styleType="ORANGE" label="Invite" onClick={handleSubmit} disabled={!isValid} />
                                {errorMsg != '' && <p className={style.formError}>{errorMsg}</p>}
                            </VZLoadingArea>
                        </Fragment>
                    );
                }}
            </Formik>
        </VZModal>
    );

    async function inviteUser(firstName: string, lastName: string, email: string) {
        try {
            setIsSending(true);

            if (userContext.authToken != null && userContext.companyId != null) {
                await authAPI.companies.addNewUser(userContext.authToken, userContext.companyId, {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                });
                props.handleCloseModal();
            }
        } catch (err) {
            toastContext.showError(err);
        } finally {
            setIsSending(false);
        }
    }
}

const Button = styled(VZActionButton)`
    justify-self: flex-end;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    width: 100px;
`;
