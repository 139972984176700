import React, { Component, useState, useContext } from 'react';
import { connect } from 'react-redux';

import { authAPI } from 'vzc-client-common';
import style from './index.module.scss';
import VZTabbedContentContainer, { IVZTab } from 'dashboard/shared/tabbed-content-container';
import VZMemberList from './member-list';
import VZInviteList from './invite-list';
import VZRequestList from './request-list';
import VZActionButton from 'dashboard/shared/action-button';
import VZConnectedInviteModal from './invite-modal';
import { IVZMyTeam } from 'interfaces/team';
import { TeamContext } from 'dashboard/shared/team-context';
import useUpdateIndicator from 'dashboard/shared/useUpdateIndicator';

interface IVZMembersTabProps {}

export default function VZMembersTab(props: IVZMembersTabProps) {
    const teamContext = useContext(TeamContext);

    const [updateIndicator, incrementUpdateIndicator] = useUpdateIndicator();
    const [currentTabId, setCurrentTabId] = useState('MEMBERS');
    const [showInviteModal, setShowInviteModal] = useState(false);

    const tabs: IVZTab[] = [
        {
            id: 'MEMBERS',
            label: 'Members',
            render: renderMemberList,
        },
        {
            id: 'INVITES',
            label: 'Pending Invites',
            render: renderPendingInvites,
        },
        {
            id: 'REQUESTS',
            label: 'Requests To Join',
            render: renderPendingRequests,
        },
    ];

    return (
        <div className={style.tab}>
            {showInviteModal && <VZConnectedInviteModal closeModal={() => setShowInviteModal(false)} onSubmitted={incrementUpdateIndicator} />}

            <VZTabbedContentContainer
                tabs={tabs}
                currentTabId={currentTabId}
                setCurrentTabId={setCurrentTabId}
                renderMiscContent={renderMiscContent}
                dependency={updateIndicator}
            />
        </div>
    );

    function renderMemberList() {
        return <VZMemberList />;
    }

    function renderPendingInvites(dependency?: any) {
        return <VZInviteList dependency={dependency} />;
    }

    function renderPendingRequests() {
        return <VZRequestList />;
    }

    function renderMiscContent() {
        return (
            teamContext.isAdminOfCurrentTeam() && (
                <VZActionButton label="INVITE MEMBERS" styleType="GREY" action="ADD" onClick={() => setShowInviteModal(true)} />
            )
        );
    }
}
