import React, { useState, useContext } from 'react';

import { Formik, Field } from 'formik';
import { string, object } from 'yup';
import ReactGA from 'react-ga';

import { Redirect, Link, match } from 'react-router-dom';

import style from './index.module.scss';
import { UserContext, authAPI, parseErrorObject, ToastContext } from 'vzc-client-common';
import { DeviceUUID } from 'device-uuid';
import qs from 'querystring';
import { Location } from 'history';
import * as Sentry from '@sentry/browser';
import useForm from 'dashboard/shared/useForm';

const vzLoginSchema = object().shape({
    email: string().min(5, 'Email too short.').required('We need your email to find your account.'),
    password: string().min(5, 'Password too short.').required('We need your password to confirm who you are.'),
});

enum VZLoginState {
    ENTER_EMAIL,
    CHECK_SSO,
    LOGIN_WITH_PASSWORD,
}

export default function VZLogin(props: { location: Location; match: match }) {
    const [success, setSuccess] = useState(false);
    const [loginState, setLoginState] = useState(VZLoginState.ENTER_EMAIL);
    const userContext = useContext(UserContext);
    const toastContext = useContext(ToastContext);

    // const {} = useForm({
    //   email: '',
    //   password: ''
    // }, {
    //   email: {
    //     type: 'string',
    //     required: true,
    //     minLength: 5
    //   }
    // }, () => {return Promise.resolve()});

    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');

    let redirect = '/dashboard';
    if (props.location.search !== '') {
        redirect = qs.parse(props.location.search.substr(1))['afterLogin'] as string;
    }

    if (!userContext.isLoggedin && !success) {
        ReactGA.pageview('/login');
        return (
            <div className={style.vzLoginContainer}>
                <div className={style.vzLoginHeader}>
                    <h3 className={style.vzLoginHeaderTitle}>Log in to Viziya Cloud</h3>
                </div>
                <div className={style.vzLoginBody}>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={vzLoginSchema}
                        onSubmit={async (values, actions) => {
                            if (values.email && values.password) {
                                try {
                                    const deviceUUID = new DeviceUUID().get();
                                    let loginResponse = await authAPI.authenticate.login(values.email, values.password, deviceUUID);

                                    if (loginResponse.success) {
                                        ReactGA.event({
                                            category: 'User',
                                            action: 'User logged in',
                                        });

                                        userContext.setAuth(loginResponse);

                                        setSuccess(true);

                                        Sentry.captureMessage(`Logged in as ${loginResponse.name.first} ${loginResponse.name.last}`);
                                    } else {
                                        setSuccess(false);
                                    }
                                } catch (err) {
                                    const errObj = parseErrorObject(err);
                                    //Log error
                                    actions.setStatus({ msg: errObj.message });
                                    Sentry.captureException(err);
                                } finally {
                                    actions.setSubmitting(false);
                                }
                            } else {
                                actions.setStatus({ msg: `Passwords don't match!` });
                                actions.setSubmitting(false); // Will set the isSubmitting to false in the event that the POST failed. Won't matter if it is successful.
                                // log error
                            }
                        }}
                        render={({ errors, status, touched, handleSubmit, isSubmitting, values }) => (
                            <form onSubmit={handleSubmit}>
                                <label className={style.vzLogInLabel}>
                                    Email:
                                    <Field type="email" name="email" className={style.vzLogInTextInput} />
                                    {errors.email && touched.email && <div className={style.vzLogInErrorMessage}>{errors.email}</div>}
                                </label>
                                {loginState === VZLoginState.LOGIN_WITH_PASSWORD && (
                                    <label className={style.vzLogInLabel}>
                                        Password:
                                        <Field type="password" name="password" className={style.vzLogInTextInput} />
                                        {errors.password && touched.password && <div className={style.vzLogInErrorMessage}>{errors.password}</div>}
                                    </label>
                                )}
                                {loginState === VZLoginState.ENTER_EMAIL && (
                                    <input
                                        type="button"
                                        value="Proceed"
                                        onClick={() => checkSSO(values.email)}
                                        className={style.vzLogInProceedButton}
                                        disabled={isSubmitting}
                                    />
                                )}
                                {loginState === VZLoginState.LOGIN_WITH_PASSWORD && (
                                    <input type="submit" value="Proceed" className={style.vzLogInProceedButton} disabled={isSubmitting} />
                                )}
                                {status && status.msg && <div className={style.vzLogInErrorMessageTotal}>{status.msg}</div>}
                            </form>
                        )}
                    />
                    <Link className={style.link} to="/forgot-password">
                        Forgot Password
                    </Link>
                    {loginState === VZLoginState.ENTER_EMAIL && (
                        <a className={style.link} onClick={() => setLoginState(VZLoginState.LOGIN_WITH_PASSWORD)}>
                            Login with Password
                        </a>
                    )}
                </div>
            </div>
        );
    } else {
        return <Redirect to={redirect}></Redirect>;
    }

    async function checkSSO(email: string) {
        setLoginState(VZLoginState.CHECK_SSO);

        try {
            const result = await authAPI.authenticate.checkUserSSOStatus(email);

            if (result.isReady === true) {
                window.location.href = result.entryPointUrl;
            } else {
                setLoginState(VZLoginState.LOGIN_WITH_PASSWORD);
            }
        } catch (err) {
            toastContext.showError(err);

            setLoginState(VZLoginState.ENTER_EMAIL);
        }
    }
}
