import React from 'react';

// import { Link } from 'react-router-dom';

import './index.scss';

type VzFooterLinkProps = {
    to: string;
    invisibleInMobile?: boolean;
};

type VzFooterLinkState = {};

export class VZFooterLink extends React.Component<VzFooterLinkProps, VzFooterLinkState> {
    constructor(props: VzFooterLinkProps) {
        super(props);
    }

    render() {
        return (
            <div className={this.props.invisibleInMobile ? 'vzInvisible vzFooterLink' : 'vzFooterLink'}>
                <a href={this.props.to} target="_blank">
                    {this.props.children}
                </a>
            </div>
        );
    }
}

export default VZFooterLink;
