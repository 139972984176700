import React from 'react';

import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom';
import { Location } from 'history';

import VZEmailConfirmBlock from 'platform/shared/email-confirm-block';

export function VZResetPasswordConfirm(props: { location: Location<{ email: string }> }) {
    const email = props.location && props.location.state && props.location.state.email;
    if (email) {
        ReactGA.pageview('/signup-confirmation');
        const emailText = email ? email : 'Something Went Wrong';
        return <VZEmailConfirmBlock email={emailText} actionText="Click on the confirmation button in the email to reset your password." />;
    } else {
        return <Redirect to="/"></Redirect>;
    }
}

export default VZResetPasswordConfirm;
