import React, { useContext, useState, useEffect, useRef } from 'react';
import { TeamContext } from 'dashboard/shared/team-context';

import style from './index.module.scss';
import VZTeamSelectorPopup from './team-selector-popup';
import disclosureImg from './disclosure.svg';
import { VZUserIcon, useLocale } from 'vzc-client-common';

export default function VZTeamSelector() {
    const teamContext = useContext(TeamContext);
    const locale = useLocale();
    const [showPopup, setShowPopup] = useState(false);

    function togglePopup() {
        setShowPopup(!showPopup);
    }

    const currentTeam = teamContext.currentTeam;

    return (
        <React.Fragment>
            {showPopup && <VZTeamSelectorPopup close={() => setShowPopup(false)} />}
            <div className={style.team} onClick={togglePopup}>
                {getContent()}
            </div>
        </React.Fragment>
    );

    function getContent() {
        if (currentTeam) {
            const countMsg = locale.formatMessage('dashboard.menu.team_selector.member_count', { count: currentTeam.users.length });
            return (
                <React.Fragment>
                    <VZUserIcon className={style.photo} src={currentTeam.iconUrl} defaultText={currentTeam.name.substr(0, 2)} />
                    <div className={style.labelSelected}>
                        {currentTeam.name}
                        <br></br>
                        <span className={style.memberCount}>{countMsg}</span>
                    </div>
                    <img src={disclosureImg} className={style.disclosure} />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className={style.label}>
                        {locale.formatMessage('dashboard.menu.team_selector.no_team_selected')}
                        <br></br>
                        <span> </span>
                    </div>
                    <img src={disclosureImg} className={style.disclosure} />
                </React.Fragment>
            );
        }
    }
}
