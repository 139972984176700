import React, { useState, useContext } from 'react';

import { Formik, Field } from 'formik';
import { string, object } from 'yup';
import ReactGA from 'react-ga';

import { Redirect, Link } from 'react-router-dom';

import style from './index.module.scss';
import { authAPI } from 'vzc-client-common';
import { UserContext } from 'vzc-client-common';
import { DeviceUUID } from 'device-uuid';

const vzLoginSchema = object().shape({
    email: string().min(5, 'Email too short.').required('We need your email to find your account.'),
});

export default function VZForgotPassword() {
    const [success, setSuccess] = useState(false);
    const [email, setEamil] = useState('');
    const userContext = useContext(UserContext);

    if (!success) {
        ReactGA.pageview('/forgot-password');
        return (
            <div className={style.vzLoginContainer}>
                <div className={style.vzLoginHeader}>
                    <h3 className={style.vzLoginHeaderTitle}>Request password reset</h3>
                </div>
                <div className={style.vzLoginBody}>
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        validationSchema={vzLoginSchema}
                        onSubmit={async (values, actions) => {
                            try {
                                if (!values.email) {
                                    throw new Error('Email required!');
                                }

                                const deviceUUID = new DeviceUUID().get();
                                let loginResponse = await authAPI.authenticate.requestPasswordReset(values.email);

                                setEamil(values.email);
                                setSuccess(true);
                                actions.setStatus({ msg: '' });
                            } catch (err) {
                                console.log(err);
                                //Log error
                                actions.setStatus({ msg: JSON.stringify(err) });
                            } finally {
                                actions.setSubmitting(false);
                            }
                        }}
                        render={({ errors, status, touched, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <label className={style.vzLogInLabel}>
                                    Email:
                                    <Field type="email" name="email" className={style.vzLogInTextInput} />
                                    {errors.email && touched.email && <div className={style.vzLogInErrorMessage}>{errors.email}</div>}
                                </label>

                                <input type="submit" value="Proceed" className={style.vzLogInProceedButton} disabled={isSubmitting} />
                                {status && status.msg && <div className={style.vzLogInErrorMessageTotal}>{status.msg}</div>}
                            </form>
                        )}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <Redirect
                to={{
                    pathname: '/password-reset-confirmation',
                    state: { email: email },
                }}
            />
        );
    }
}
