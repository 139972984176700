import React, { Component, useState, useContext } from 'react';
import { connect } from 'react-redux';

import IVZTeamMember from 'interfaces/teamMember';
import { authAPI } from 'vzc-client-common';

import style from './index.module.scss';
import VZDashboardTable, { useDashboardTable, IVZDashboardTableColumn } from 'dashboard/shared/dashboard-table';
import VZRowButton, { VZRowButtonType, VZRowButtonTextStyle } from 'dashboard/shared/row-button';
import IVZTeamAccessRequest from 'interfaces/teamAccessRequest';
import { ToastContext, UserContext } from 'vzc-client-common';
import { TeamContext } from 'dashboard/shared/team-context';

interface IVZRequestListProps {}

export default function VZRequestList(props: IVZRequestListProps) {
    const teamContext = useContext(TeamContext);
    const userContext = useContext(UserContext);

    const inviteColumns: IVZDashboardTableColumn<IVZTeamAccessRequest>[] = [
        {
            columnId: 'PHOTO',
            label: '',
            width: 60,
            render: (request) => {
                if(request.requestedUser === null || request.requestedUser === undefined) {
                    return <></>
                }
                return <img src={request.requestedUser.iconUrl} className={style.photo}></img>;
            },
        },
        {
            columnId: 'NAME',
            label: 'NAME/EMAIL',
            width: 200,
            render: (request) => {
                if(request.requestedUser === null || request.requestedUser === undefined) {
                    return <>User does not exist</>
                }
                return (
                    <div>
                        <span className={style.name}>
                            {request.requestedUser.name.first} {request.requestedUser.name.last}
                        </span>
                        <br></br>
                        <span className={style.email}>{request.requestedUser.email}</span>
                    </div>
                );
            },
        },
        {
            columnId: 'REQUESTED_ON',
            label: 'REQUESTED ON',
            width: 200,
            render: (request) => {
                if(request.requestedUser === null || request.requestedUser === undefined) {
                    return <>User does not exist</>
                }
                return <div>{request.createdDate}</div>;
            },
        },
        {
            columnId: 'ACTIONS',
            label: 'ACTIONS',
            minWidth: 205,
            width: 200,
            flexGrow: 1,
            render: (request, classes) => {
                if(request.requestedUser === null || request.requestedUser === undefined) {
                    return <>User does not exist</>
                }
                return (
                    
                    <div className={classes.actions}>
                        {teamContext.isAdminOfCurrentTeam() && <VZRowButton label="Accept" onClick={() => acceptRequest(request)} />}
                        {teamContext.isAdminOfCurrentTeam() && <VZRowButton textStyle={VZRowButtonTextStyle.ERROR} label="Decline" />}
                    </div>
                );
            },
        },
    ];
    const tableProps = useDashboardTable(inviteColumns, fetchRequests, [teamContext.currentTeam]);

    const toastContext = useContext(ToastContext);

    return (
        <VZDashboardTable<IVZTeamAccessRequest>
            searchBarPlaceholder="Search for Requests"
            countSuffix={'dashboard.teams.requests.count'}
            {...tableProps}
        />
    );

    function fetchRequests(search?: string) {
        if (!userContext.authToken) {
            return Promise.resolve([]);
        }

        if (teamContext.currentTeam) {
            return authAPI.requests.getTeamRequests(userContext.authToken, teamContext.currentTeam._id, search);
        }
        return Promise.resolve([]);
    }

    async function acceptRequest(request: IVZTeamAccessRequest) {
        if (!userContext.authToken) {
            return;
        }

        try {
            await authAPI.requests.completeRequest(userContext.authToken, request._id, request.confirmationCode);

            tableProps.refresh();

            toastContext.showToast('Request Accepted!');
        } catch (err) {
            toastContext.showToast(err, 'ERROR');
        }
    }
}
