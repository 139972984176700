import React, { useState, useContext, useEffect } from 'react';

import style from './index.module.scss';
import VZDashboardBox, { VZDashboardBoxRow, VZDashboardBoxColumn, boxStyle, VZDashboardBoxFooter } from 'dashboard/shared/dashboard-box';
import { VZInput, ToastContext, UserContext } from 'vzc-client-common';
import { TeamContext } from 'dashboard/shared/team-context';
import VZRowButton, { VZRowButtonType } from 'dashboard/shared/row-button';
import { IVZCompany } from 'interfaces/company';
import { authAPI, parseErrorObject, uploadFileToS3, formatMemorySize } from 'vzc-client-common';
import VZLoadingIndicator from 'dashboard/shared/loading-indicator';
import VZActionButton from 'dashboard/shared/action-button';
import { VZPrompt } from 'shared/VZPrompt';
import styled from 'styled-components';

export default function VZManageTab() {
    const teamContext = useContext(TeamContext);
    const toastContext = useContext(ToastContext);
    const userContext = useContext(UserContext);

    const [teamName, setTeamName] = useState<string>(teamContext.currentTeam ? teamContext.currentTeam.name : '');
    const [isLoadingCompany, setIsLoadingCompany] = useState(false);
    const [company, setCompany] = useState<IVZCompany | null>(null);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [logoFile, setLogoFile] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const maxFileSize = 1024 * 1024 * 10; // 10 MB.

    // Update fields when team changes.
    useEffect(() => {
        setTeamName(teamContext.currentTeam ? teamContext.currentTeam.name : '');
        loadCompany();
    }, [teamContext.currentTeam]);

    return (
        <div className={style.tab}>
            <div className={style.headerContainer}></div>
            <VZDashboardBox title="Manage">{teamContext.currentTeam && getContent()}</VZDashboardBox>
        </div>
    );

    function getContent() {
        return (
            <React.Fragment>
                {showDeletePrompt && (
                    <VZPrompt
                        title="Delete Team"
                        okText="Ok"
                        children={renderDeletePromptChildren()}
                        onOk={() => setShowDeletePrompt(false)}
                        cancelText="Cancel"
                        onClose={() => setShowDeletePrompt(false)}
                    />
                )}
                <VZDashboardBoxRow>
                    <VZDashboardBoxColumn>
                        <div className={boxStyle.label}>Team Name</div>
                    </VZDashboardBoxColumn>
                    <VZDashboardBoxColumn>
                        <VZInput
                            className={style.formTextInput}
                            value={teamName}
                            setValue={setTeamName}
                            disabled={!teamContext.isAdminOfCurrentTeam()}
                        />
                    </VZDashboardBoxColumn>
                </VZDashboardBoxRow>

                <VZDashboardBoxRow>
                    <VZDashboardBoxColumn>
                        <div className={boxStyle.label}>Logo</div>
                    </VZDashboardBoxColumn>
                    <VZDashboardBoxColumn>
                        <img src={teamContext.currentTeam!.iconUrl} className={style.logo} />
                        <input type="file" name="pic" accept="image/png,image/jpg" onChange={(e) => setFile(e.target.files && e.target.files[0])} />
                        <div>{logoFile}</div>
                    </VZDashboardBoxColumn>
                </VZDashboardBoxRow>

                <VZDashboardBoxRow>
                    <VZDashboardBoxColumn>
                        <div className={boxStyle.label}>Company</div>
                    </VZDashboardBoxColumn>
                    <VZDashboardBoxColumn>
                        {isLoadingCompany ? (
                            <VZLoadingIndicator />
                        ) : (
                            <div className={boxStyle.value}>{company ? company.name : 'COMPANY NOT LOADED!'}</div>
                        )}
                    </VZDashboardBoxColumn>
                </VZDashboardBoxRow>

                <VZDashboardBoxRow>
                    <VZDashboardBoxColumn>
                        <div className={boxStyle.label}>Domain</div>
                    </VZDashboardBoxColumn>
                    <VZDashboardBoxColumn>
                        <div className={boxStyle.value}>{teamContext.currentTeam!.domain}</div>
                    </VZDashboardBoxColumn>
                </VZDashboardBoxRow>

                <VZDashboardBoxRow>
                    <VZDashboardBoxColumn>
                        <div className={boxStyle.label}>Delete</div>
                    </VZDashboardBoxColumn>
                    <VZDashboardBoxColumn>
                        {isDeleting ? (
                            <label className={boxStyle.value}>DELETING...</label>
                        ) : (
                            <VZRowButton actionType={VZRowButtonType.DELETE} onClick={() => setShowDeletePrompt(true)} />
                        )}
                    </VZDashboardBoxColumn>
                </VZDashboardBoxRow>

                <VZDashboardBoxFooter>
                    <VZDashboardBoxColumn>
                        <VZActionButton styleType="ORANGE" label="Save" onClick={modifyTeam} disabled={isSaving} />
                    </VZDashboardBoxColumn>
                </VZDashboardBoxFooter>
            </React.Fragment>
        );
    }

    async function loadCompany() {
        if (!isLoadingCompany && userContext.authToken && teamContext.currentTeam) {
            try {
                setIsLoadingCompany(true);

                const loadedCompany = await authAPI.companies.getCompany(userContext.authToken, teamContext.currentTeam.company);
                setCompany(loadedCompany);
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoadingCompany(false);
            }
        }
    }

    function renderDeletePromptChildren() {
        if (teamContext.currentTeam) {
            return (
                <DeletePromptContainer>
                    <span>Please raise a Support ticket with the following information:</span>
                    <DeletePromptText>
                        {`This is a Request to delete Team {${teamContext.currentTeam.name}} (#${teamContext.currentTeam._id})`}
                    </DeletePromptText>
                </DeletePromptContainer>
            );
        }
        return <span>Please select a team.</span>;
    }

    async function deleteTeam() {
        if (teamContext.currentTeam && userContext.authToken) {
            try {
                setShowDeletePrompt(false);
                setIsDeleting(true);

                await authAPI.teams.deleteTeam(userContext.authToken, teamContext.currentTeam._id);

                toastContext.showToast('Team Deleted!');

                teamContext.setCurrentTeam(null);
            } catch (err) {
                toastContext.showError(parseErrorObject(err));
            } finally {
                setIsDeleting(false);
            }
        }
    }

    async function modifyTeam() {
        if (teamContext.currentTeam && userContext.authToken) {
            try {
                setIsSaving(true);

                let iconUrl = undefined;
                if (file) {
                    if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
                        throw new Error('Unsupported file type!');
                    }

                    if (file.size > maxFileSize) {
                        throw new Error(`File is too big, max is ${formatMemorySize(maxFileSize)}`);
                    }

                    const iconPostData = await authAPI.teams.getIconPostUrl(userContext.authToken, teamContext.currentTeam!._id, file.type);
                    await uploadFileToS3(iconPostData, file);
                    iconUrl = iconPostData.contentURL;
                }

                const team = await authAPI.teams.modifyTeam(userContext.authToken, teamContext.currentTeam._id, {
                    name: teamName,
                    iconUrl: iconUrl,
                });

                toastContext.showToast('Team Updated!');

                teamContext.setCurrentTeam({
                    ...teamContext.currentTeam,
                    name: teamName,
                });
            } catch (err) {
                toastContext.showError(parseErrorObject(err));
            } finally {
                setIsSaving(false);
            }
        }
    }
}

const DeletePromptContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    font-size: 13px;
    color: #555555;
`;

const DeletePromptText = styled.span`
    margin-top: 10px;
    font-weight: 700;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
`;
