import React from 'react';

import './index.scss';
// import footerLogo from './footerLogo.svg';
import facebookLogo from './facebook.svg';
import linkedInLogo from './linkedin.svg';
import twitterLogo from './twitter.svg';
import youtubeLogo from './youtube.svg';

import VzFooterLink from '../footer-link';

type VzFooterProps = {};

type VzFooterState = {};

export class VZFooter extends React.Component<VzFooterProps, VzFooterState> {
    constructor(props: VzFooterProps) {
        super(props);
    }

    render() {
        return (
            <div className="vzFooter">
                {/* <div className="vzFooterLogoContainer">
          <img src={footerLogo} className="vzFooterLogo" alt="Viziya Cloud Logo" />
        </div> */}
                <div className="vzFooterLinkRegion">
                    <div className="vzFooterLinksContainer">
                        <div className="vzFooterLinks">
                            <VzFooterLink to="/service-agreement">Service Agreement</VzFooterLink>
                            <VzFooterLink to="/eula">EULA</VzFooterLink>
                            <VzFooterLink to="/about-us" invisibleInMobile>
                                About Us
                            </VzFooterLink>
                            <VzFooterLink to="/faq" invisibleInMobile>
                                FAQ
                            </VzFooterLink>
                            <VzFooterLink to="/contact-us" invisibleInMobile>
                                Contact Us
                            </VzFooterLink>
                        </div>
                    </div>
                </div>
                <div className="vzSocialIconsContainer">
                    <div className="vzFooterLegal">Viziya - All Rights Reserved 2019{/*   - Privacy Policy - Legal */}</div>
                    <div className="vzSocialIcons">
                        <VzFooterLink to="https://www.facebook.com/VIZIYACorp/">
                            <img src={facebookLogo} alt="Viziya Facebook" />
                        </VzFooterLink>
                        <VzFooterLink to="https://twitter.com/viziyacorp">
                            <img src={twitterLogo} alt="Viziya Twitter" />
                        </VzFooterLink>
                        <VzFooterLink to="https://www.linkedin.com/company/viziya-corp/">
                            <img src={linkedInLogo} alt="Viziya LinkedIn" />
                        </VzFooterLink>
                        <VzFooterLink to="https://www.youtube.com/channel/UCscmJMPwMKPie-ICRQ-NT9w">
                            <img src={youtubeLogo} alt="Viziya YouTube" />
                        </VzFooterLink>
                    </div>
                </div>
            </div>
        );
    }
}

export default VZFooter;
