import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { envHelper, ToastContext, authAPI, notificationAPI, coreAPI, VZModal } from 'vzc-client-common';

interface IVZProps {
    onClose: () => void;
}

/** Modal for displaying application meta information. */
export function VZAboutModal(props: IVZProps) {
    const toastContext = useContext(ToastContext);
    const [isLoading, setIsLoading] = useState(false);
    const [authBuildNumber, setAuthBuildNumber] = useState('');
    const [coreBuildNumber, setCoreBuildNumber] = useState('');
    const [notifBuildNumber, setNotifBuildNumber] = useState('');

    useEffect(() => {
        loadServiceStatuses();
    }, []);

    return (
        <VZModal title="About" closeModal={props.onClose}>
            <TitleLabel>VIZIYA® WorkAlign® Cloud for Oracle Cloud</TitleLabel>

            <Row>
                <Label>Environment</Label>
                <Value>{envHelper.getAppEnv()}</Value>
            </Row>

            <TitleLabel>Build Numbers</TitleLabel>

            <Row>
                <Label>Platform Client</Label>
                <Value>{envHelper.getBuildNumber()}</Value>
            </Row>

            <Row>
                <Label>Auth API</Label>
                <Value>{authBuildNumber}</Value>
            </Row>

            <Row>
                <Label>Core API</Label>
                <Value>{coreBuildNumber}</Value>
            </Row>

            <Row>
                <Label>Notification API</Label>
                <Value>{notifBuildNumber}</Value>
            </Row>
        </VZModal>
    );

    async function loadServiceStatuses() {
        try {
            setIsLoading(true);

            const { data: coreAPIStatus } = await coreAPI.getStatus();
            const { data: authAPIStatus } = await authAPI.getStatus();
            const notifAPIStatus = await notificationAPI.getStatus();

            setCoreBuildNumber(coreAPIStatus.buildNumber);
            setAuthBuildNumber(authAPIStatus.buildNumber);
            setNotifBuildNumber(notifAPIStatus.data.buildNumber);
        } catch (err) {
            toastContext.showError(err);
        } finally {
            setIsLoading(false);
        }
    }
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const TitleLabel = styled.h2`
    color: #ed8b00;
    font-size: 14px;
`;

const Label = styled.p`
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #9b9b9b;
    flex-basis: 200px;
    flex-grow: 0;
`;

const Value = styled.p`
    font-size: 13px;
    letter-spacing: -0.37px;
    color: #676666;
`;
