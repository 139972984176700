import React, { ReactNode } from 'react';

import style from './index.module.scss';
import { symbol } from 'prop-types';

interface IVZProps {
    children?: ReactNode;
    title: string;
    miscLabel?: string;
}

export default function VZDashboardBox(props: IVZProps) {
    return (
        <div className={style.box}>
            <div className={style.header}>
                <div className={style.title}>{props.title}</div>
                <div className={style.miscLabel}>{props.miscLabel}</div>
            </div>
            <div className={style.bodyScrollContainer}>
                <div className={style.body}>{props.children}</div>
            </div>
        </div>
    );
}

export function VZDashboardBoxRow(props: { children?: ReactNode }) {
    return <div className={style.row}>{props.children}</div>;
}

export function VZDashboardBoxColumn(props: { children?: ReactNode }) {
    return <div className={style.column}>{props.children}</div>;
}

export function VZDashboardBoxFooter(props: { children?: ReactNode }) {
    return <div className={style.footer}>{props.children}</div>;
}

export const boxStyle = {
    label: style.label,
    value: style.value,
};
