import React, { useContext } from 'react';

import { TeamContext } from 'dashboard/shared/team-context';

import { authAPI } from 'vzc-client-common';
import VZDashboardTable, { useDashboardTable, IVZDashboardTableColumn } from 'dashboard/shared/dashboard-table';
import IVZTeamPackage from 'interfaces/teamPackage';
import { VZBillingType } from 'interfaces/team';
import { UserContext } from 'vzc-client-common';

export default function VZPackageList() {
    const teamContext = useContext(TeamContext);
    const userContext = useContext(UserContext);

    const columns: IVZDashboardTableColumn<IVZTeamPackage>[] = [
        {
            columnId: 'NAME',
            label: 'NAME',
            width: 300,
            render: (teamPackage, classes) => {
                return <div className={classes.nameLabel}>{teamPackage.packageName}</div>;
            },
        },
        {
            columnId: 'USED_QUANTITY',
            label: 'USED',
            width: 300,
            render: (teamPackage, classes) => {
                return <div className={classes.nameLabel}>{teamPackage.usedQuantity}</div>;
            },
        },
        ...getColumnsBasedOnBillingType(),
    ];

    const tableProps = useDashboardTable(columns, fetchPackages, [teamContext.currentTeam]);

    return (
        <React.Fragment>
            <VZDashboardTable searchBarPlaceholder="Search for Packages" countSuffix={'dashboard.teams.packages.count'} {...tableProps} />
        </React.Fragment>
    );

    async function fetchPackages(search?: string) {
        if (teamContext.currentTeam && userContext.authToken) {
            return await authAPI.packages.getTeamPackages(userContext.authToken, teamContext.currentTeam._id, search);
        } else {
            return [] as IVZTeamPackage[];
        }
    }

    function getColumnsBasedOnBillingType(): IVZDashboardTableColumn<IVZTeamPackage>[] {
        if (teamContext.currentTeam && teamContext.currentTeam.billingType === VZBillingType.DYNAMIC) {
            return [
                {
                    columnId: 'COST_PER_USER',
                    label: 'COST/USER',
                    width: 300,
                    render: (teamPackage, classes) => {
                        return <div className={classes.nameLabel}>${teamPackage.costPerUser}/user</div>;
                    },
                },
                {
                    columnId: 'COST_PER_MONTH',
                    label: 'COST/MONTH',
                    width: 300,
                    render: (teamPackage, classes) => {
                        return <div className={classes.nameLabel}>${teamPackage.costPerMonth}/mo</div>;
                    },
                },
            ];
        } else {
            return [
                {
                    columnId: 'TOTAL_QUANTITY',
                    label: 'TOTAL',
                    width: 300,
                    render: (teamPackage, classes) => {
                        return <div className={classes.nameLabel}>{teamPackage.totalQuantity}</div>;
                    },
                },
            ];
        }
    }
}
