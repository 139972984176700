import React, { ReactNode } from 'react';

import style from './index.module.scss';

export interface IVZTab {
    id: string;
    label: string;
    render: (dependency?: any) => ReactNode;
}

export interface IVZTabbedContentContainerProps {
    dependency?: any;
    tabs: IVZTab[];
    currentTabId: string;
    setCurrentTabId: (newTabId: string) => void;

    // Renders misc content, is inside row-flex
    renderMiscContent?: () => ReactNode;
}

export default function VZTabbedContentContainer(props: IVZTabbedContentContainerProps) {
    return (
        <div className={style.masterContainer}>
            <div className={style.header}>
                <div className={style.tabLabelList}>{renderTabList()}</div>
                <div className={style.miscContent}>{props.renderMiscContent && props.renderMiscContent()}</div>
            </div>
            <div className={style.content}>{renderCurrentTabContent()}</div>
        </div>
    );

    function renderCurrentTabContent() {
        const currentTab = getCurrentTab();

        if (currentTab) {
            return currentTab.render(props.dependency);
        }
        return `TAB ${props.currentTabId} not found!`;
    }

    function getCurrentTab() {
        return props.tabs.find((tab) => tab.id === props.currentTabId);
    }

    function renderTabList() {
        return props.tabs.map((tab) => {
            const isCurrentTab = tab.id === props.currentTabId;
            const className = isCurrentTab ? style.tabLabel_Selected : style.tabLabel;

            return (
                <div key={tab.id} className={className} onClick={() => props.setCurrentTabId(tab.id)}>
                    {tab.label}
                </div>
            );
        });
    }
}
