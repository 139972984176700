import React, { Component } from 'react';
import style from './index.module.scss';
import { combineClassNames } from 'vzc-client-common';

import addImg from './add-grey.svg';

export type VZButtonStyleType = 'WHITE' | 'GREY' | 'ORANGE' | 'DELETE';

export type VZAction = 'ADD';

interface IVZActionButtonProps {
    label: string;
    disabled?: boolean;
    styleType: VZButtonStyleType;
    action?: VZAction;
    className?: string;
    onClick?: () => void;
}

interface IVZActionButtonState {}

export default class VZActionButton extends Component<IVZActionButtonProps, IVZActionButtonState> {
    render() {
        let imgSrc = null;

        switch (this.props.action) {
            case 'ADD':
                imgSrc = addImg;
                break;
        }

        return (
            <button
                className={combineClassNames(style[this.props.styleType.toLowerCase()], this.props.className)}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
            >
                {imgSrc && <img className={style.image} src={imgSrc} alt={this.props.label} />}
                {this.props.label !== '' && <span className={style.label}>{this.props.label}</span>}
            </button>
        );
    }
}
