import React, { useState, useEffect } from 'react';

import style from './index.module.scss';
import VZSearchBar from 'dashboard/shared/search-bar';
import { removeAtIndex, combineClassNames } from 'vzc-client-common';

import assignImg from './assign.svg';
import unAssignImg from './unassign.svg';
import VZTooltipBlock from 'dashboard/shared/tooltip-block';

interface IVZAssignmentTableProps<TModel> {
    className?: string;
    availableListTitle: string;
    assignedListTitle: string;
    availableSearchPlaceholder: string;
    assignedSearchPlaceholder: string;

    availableList: TModel[];
    assignedList: TModel[];

    setAvailableList: (newList: TModel[]) => void;
    setAssignedList: (newList: TModel[]) => void;
    search: (model: TModel[], search: string) => TModel[];
    getText: (item: TModel) => string;
    getTooltip?: (item: TModel) => string;
}

export default function VZAssignmentTable<TModel>(props: IVZAssignmentTableProps<TModel>) {
    const [availableSearch, setAvailableSearch] = useState('');
    const [assignedSearch, setAssignedSearch] = useState('');
    const [availableSelectedIndexes, setAvailableSelectedIndexes] = useState<number[]>([]);
    const [assignedSelectedIndexes, setAssignedSelectedIndexes] = useState<number[]>([]);

    useEffect(() => {
        setAvailableSelectedIndexes([]);
    }, [availableSearch, props.availableList]);

    useEffect(() => {
        setAssignedSelectedIndexes([]);
    }, [assignedSearch, props.assignedList]);

    let assignButtonClasses = combineClassNames(style.middleButton, style.assign);
    if (availableSelectedIndexes.length === 0) {
        assignButtonClasses = combineClassNames(assignButtonClasses, style.middleButtonDisabled);
    }

    let unAssignButtonClasses = combineClassNames(style.middleButton, style.unAssign);
    if (assignedSelectedIndexes.length === 0) {
        unAssignButtonClasses = combineClassNames(unAssignButtonClasses, style.middleButtonDisabled);
    }

    return (
        <div className={combineClassNames(style.container, props.className)}>
            <div className={style.availableContainer}>
                <div className={style.listTitle}>{props.availableListTitle}</div>
                <div className={style.listOuter}>
                    <VZSearchBar
                        placeholder={props.availableSearchPlaceholder}
                        searchBarClassName={style.searchBar}
                        search={availableSearch}
                        onSearchChanged={setAvailableSearch}
                    />
                    <div className={style.listScrollContainer}>
                        <div className={style.listContent}>{renderAvailableList()}</div>
                    </div>
                </div>
            </div>
            <div className={style.middleArea}>
                <img className={assignButtonClasses} onClick={assign} src={assignImg} />
                <img className={unAssignButtonClasses} onClick={unAssign} src={unAssignImg} />
            </div>
            <div className={style.assignedContainer}>
                <div className={style.listTitle}>{props.assignedListTitle}</div>
                <div className={style.listOuter}>
                    <VZSearchBar
                        placeholder={props.assignedSearchPlaceholder}
                        searchBarClassName={style.searchBar}
                        search={assignedSearch}
                        onSearchChanged={setAssignedSearch}
                    />
                    <div className={style.listScrollContainer}>
                        <div className={style.listContent}>{renderAssignedList()}</div>
                    </div>
                </div>
            </div>
        </div>
    );

    function renderAvailableList() {
        return applySearch(props.availableList, availableSearch).map((item, index) =>
            renderItem(item, index, isSelected(index, availableSelectedIndexes), selectAvailableItem)
        );
    }

    function renderAssignedList() {
        return applySearch(props.assignedList, assignedSearch).map((item, index) =>
            renderItem(item, index, isSelected(index, assignedSelectedIndexes), selectedAssignedItem)
        );
    }

    function isSelected(itemIndex: number, indexArray: number[]) {
        return indexArray.find((idx) => idx === itemIndex) !== undefined;
    }

    function applySearch(list: TModel[], search: string) {
        return props.search(list, search);
    }

    function selectAvailableItem(availableIndex: number) {
        const index = availableSelectedIndexes.findIndex((i) => i === availableIndex);

        if (index !== -1) {
            setAvailableSelectedIndexes(removeAtIndex(availableSelectedIndexes, index));
        } else {
            setAvailableSelectedIndexes([...availableSelectedIndexes, availableIndex]);
        }
    }

    function selectedAssignedItem(assignedIndex: number) {
        const index = assignedSelectedIndexes.findIndex((i) => i === assignedIndex);

        if (index !== -1) {
            setAssignedSelectedIndexes(removeAtIndex(assignedSelectedIndexes, index));
        } else {
            setAssignedSelectedIndexes([...assignedSelectedIndexes, assignedIndex]);
        }
    }

    function assign() {
        const newAvailList = props.availableList.filter((item, index) => !isSelected(index, availableSelectedIndexes));
        const addToAssigned = props.availableList.filter((item, index) => isSelected(index, availableSelectedIndexes));

        props.setAvailableList(newAvailList);
        props.setAssignedList([...props.assignedList, ...addToAssigned]);
    }

    function unAssign() {
        const newAssignList = props.assignedList.filter((item, index) => !isSelected(index, assignedSelectedIndexes));
        const addToAvail = props.assignedList.filter((item, index) => isSelected(index, assignedSelectedIndexes));

        props.setAssignedList(newAssignList);
        props.setAvailableList([...props.availableList, ...addToAvail]);
    }

    function renderItem(model: TModel, index: number, selected: boolean, onClick: (index: number) => void) {
        const selectedClass = selected ? style.selected : null;
        const toolip = props.getTooltip && props.getTooltip(model);
        return (
            <div key={index} className={combineClassNames(style.item, selectedClass)} onClick={() => onClick(index)}>
                <VZTooltipBlock title={props.getText(model)} message={toolip}>
                    {props.getText(model)}
                </VZTooltipBlock>
            </div>
        );
    }
}
