import React, { useState, createContext, useEffect, useContext, ReactNode } from 'react';
import { IVZMyTeam } from 'interfaces/team';
import { ToastContext, UserContext } from 'vzc-client-common';
import { parseErrorObject, authAPI } from 'vzc-client-common';

export interface IVZTeamContext {
    myTeams: IVZMyTeam[];
    currentTeam: IVZMyTeam | null;
    isLoadingTeams: boolean;

    setCurrentTeam: (team: IVZMyTeam | null) => void;
    isAdminOfCurrentTeam: () => boolean;
}

export const TeamContext = createContext<IVZTeamContext>({
    myTeams: [],
    currentTeam: null,
    isLoadingTeams: false,

    setCurrentTeam: () => {},
    isAdminOfCurrentTeam: () => false,
});

interface IVZTeamContextProviderProps {
    children?: ReactNode;
}

export function VZTeamContextProvider(props: IVZTeamContextProviderProps) {
    const [myTeams, setMyTeams] = useState<IVZMyTeam[]>([]);
    const [currentTeam, setCurrentTeam] = useState<IVZMyTeam | null>(null);
    const [isLoadingTeams, setIsLoadingTeams] = useState(false);
    const userContext = useContext(UserContext);

    const toastContext = useContext(ToastContext);

    useEffect(() => {
        loadMyTeams();
    }, []);

    const contextValue = {
        myTeams,
        currentTeam,
        isLoadingTeams,

        setCurrentTeam,
        isAdminOfCurrentTeam: () => currentTeam != null && currentTeam.isUserAdmin,
    };

    return <TeamContext.Provider value={contextValue}>{props.children}</TeamContext.Provider>;

    async function loadMyTeams() {
        if (!userContext.authToken) {
            return;
        }

        try {
            setIsLoadingTeams(true);

            const newMyTeams = await authAPI.teams.getMyTeams(userContext.authToken);

            setMyTeams(newMyTeams);
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
            setIsLoadingTeams(false);
        }
    }
}
