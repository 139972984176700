import React, { Component, useState, useContext } from 'react';
import { connect } from 'react-redux';

import style from './index.module.scss';
import VZTabbedContentContainer, { IVZTab } from 'dashboard/shared/tabbed-content-container';
import useUpdateIndicator from 'dashboard/shared/useUpdateIndicator';
import VZMemberList from 'dashboard/company-page/manage-members-tab/member-list';
import { TeamContext } from 'dashboard/shared/team-context';
import VZActionButton from 'dashboard/shared/action-button';
import VZInviteModal from './invite-modal';

interface IVZCompanyMembersTab {}

export default function VZCompanyMembersTab(props: IVZCompanyMembersTab) {
    const teamContext = useContext(TeamContext);
    const [updateIndicator, incrementUpdateIndicator] = useUpdateIndicator();
    const [currentTabId, setCurrentTabId] = useState('MEMBERS');
    const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);

    const tabs: IVZTab[] = [
        {
            id: 'MEMBERS',
            label: 'Members',
            render: renderMemberList,
        },
    ];

    return (
        <div className={style.tab}>
            {showInviteMemberModal && (
                <VZInviteModal handleCloseModal={() => setShowInviteMemberModal(false)} onSubmitted={incrementUpdateIndicator} />
            )}

            <VZTabbedContentContainer
                tabs={tabs}
                currentTabId={currentTabId}
                setCurrentTabId={setCurrentTabId}
                renderMiscContent={renderMiscContent}
                dependency={updateIndicator}
            />
        </div>
    );

    function renderMemberList() {
        return <VZMemberList />;
    }

    function renderMiscContent() {
        return <VZActionButton label="INVITE MEMBERS" styleType="GREY" action="ADD" onClick={() => setShowInviteMemberModal(true)} />;
    }
}
