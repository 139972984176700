import React, { useState, Component, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { VZModal, UserContext, VZUserIcon, useDebounce } from 'vzc-client-common';
import IVZTeam, { IVZJoinableTeam } from 'interfaces/team';
import { authAPI, parseErrorObject, removeAtIndex } from 'vzc-client-common';
import style from './index.module.scss';
import defaultTeamIcon from './viziya-logo.svg';
import VZSearchBar from 'dashboard/shared/search-bar';
import { duration } from 'moment';

import validTeamImg from './validTeam.svg';
import invalidTeamImg from './invalidTeam.png';
import VZLoadingIndicator from 'dashboard/shared/loading-indicator';

interface IVZAddTeamModalProps {
    closeModal?: () => void;
}

export default function VZAddTeamModel(props: IVZAddTeamModalProps) {
    const userContext = useContext(UserContext);

    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [teams, setTeams] = useState<IVZJoinableTeam[]>([]);
    const [activeRequests, setActiveRequests] = useState<string[]>([]);
    const [teamName, setTeamName] = useState('');
    const [isCheckingTeamName, setIsCheckingTeamName] = useState(false);
    const [teamNameError, setTeamNameError] = useState<string | null>(null);
    const debouncedLoad = useDebounce(loadTeams, duration(1, 'seconds'));
    const debouncedValidateTeamName = useDebounce(validateTeamName, duration(1, 'seconds'));

    useEffect(() => {
        debouncedLoad();
    }, [search]);

    useEffect(() => {
        debouncedValidateTeamName();
    }, [teamName]);

    return (
        <VZModal title="ADD A TEAM" closeModal={props.closeModal}>
            <div className={style.modalHeading}>REQUEST ACCESS TO TEAMS</div>

            <VZSearchBar
                search={search}
                onSearchChanged={setSearch}
                searchBarClassName={style.searchBar}
                loadingClassName={style.loadingIndicator}
                isLoading={isLoading}
            />
            <div className={style.teamList}>{renderTeamList()}</div>

            <div className={style.modalHeading}>CREATE A NEW TEAM</div>
            <input
                className={style.teamNameInput}
                type="text"
                placeholder="Enter a name for your team"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
            />
            {isCheckingTeamName ? (
                <div className={style.teamNameMessage}>
                    <VZLoadingIndicator />
                    <div>Checking</div>
                </div>
            ) : (
                <div className={style.teamNameMessage}>
                    <img src={!teamNameError ? validTeamImg : invalidTeamImg} />
                    <div>{teamNameError || 'Team name is available!'}</div>
                </div>
            )}

            <button className={style.finishButton} onClick={createTeam}>
                FINISH
            </button>
            <p className={style.linkButton} onClick={props.closeModal}>
                Skip this step for now
            </p>
        </VZModal>
    );

    async function validateTeamName() {
        if (!userContext.authToken) {
            return;
        }

        try {
            setIsCheckingTeamName(true);

            if (teamName.length < 5 || teamName.length > 20) {
                throw new Error('Team name must be between 5 and 20 characters!');
            }

            const teams = await authAPI.teams.getTeams(userContext.authToken, {
                domain: userContext.domain,
                name: teamName,
            });

            if (teams.length > 0) {
                throw new Error('Team name already taken!');
            }

            setTeamNameError(null);
        } catch (err) {
            const errObj = parseErrorObject(err);
            setTeamNameError(errObj.message);
        } finally {
            setIsCheckingTeamName(false);
        }
    }

    async function loadTeams() {
        if (!userContext.authToken) {
            return;
        }

        try {
            setIsLoading(true);
            const teams = await authAPI.teams.getTeams(userContext.authToken, {
                domain: userContext.domain,
                search,
            });
            console.log(teams);
            setTeams(teams);
        } catch (err) {
            console.log(err);
            setTeams([]);
        } finally {
            setIsLoading(false);
        }
    }

    async function createTeam() {
        if (!userContext.authToken) {
            return;
        }

        try {
            let { team, newToken } = await authAPI.teams.createTeam(userContext.authToken, teamName);
            if (props.closeModal) {
                props.closeModal();
            }
            console.log(`NEW TOKEN: ${newToken}`);
            userContext.setAuthFromToken(newToken);
        } catch (err) {
            console.log(err);
        }
    }

    function renderTeamList() {
        return teams.map((team) => {
            const isProcessing = activeRequests.findIndex((ar) => ar === team._id) !== -1;
            let text = '';

            if (isProcessing) {
                text = 'Sending Request...';
            } else if (team.hasRequestedAccess) {
                text = 'Request Sent';
            } else {
                text = 'Request Access';
            }

            return (
                <div key={team._id} className={style.teamBlock}>
                    <VZUserIcon className={style.teamIcon} src={team.iconUrl} defaultText={team.name.substr(0, 2)} />
                    <p className={style.teamName}>{team.name}</p>
                    <button
                        className={team.hasRequestedAccess || isProcessing ? style.requestSent : style.requestAccess}
                        onClick={() => requestAccess(team)}
                        disabled={team.hasRequestedAccess || isProcessing}
                    >
                        {text}
                    </button>
                </div>
            );
        });
    }

    async function requestAccess(team: IVZTeam) {
        if (!userContext.authToken) {
            return;
        }

        try {
            setActiveRequests([...activeRequests, team._id]);

            await authAPI.requests.requestAccess(userContext.authToken, team._id);

            setActiveRequests(
                removeAtIndex(
                    activeRequests,
                    activeRequests.findIndex((ar) => ar === team._id)
                )
            );

            loadTeams();
        } catch (err) {
            console.log(err);
        }
    }
}
