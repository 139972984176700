import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import style from './index.module.scss';
import { combineClassNames } from 'vzc-client-common';

interface IVZNavLinkProps {
    imageSrc: string;
    label: string;
    url: string;
    className?: string;
    onClick?: () => void;
}

interface IVZNavLinkState {}

export default class VZNavLink extends Component<IVZNavLinkProps, IVZNavLinkState> {
    render() {
        return (
            <NavLink
                className={combineClassNames(style.container, this.props.className)}
                to={this.props.url}
                activeClassName={style.active}
                onClick={this.props.onClick}
            >
                <img className={style.image} src={this.props.imageSrc} />
                <p className={style.label}>{this.props.label}</p>
            </NavLink>
        );
    }
}
