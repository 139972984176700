import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import VZApp from './App';
import * as serviceWorker from './serviceWorker';
import { initSentry } from 'vzc-client-common';

console.log(`Env: ${process.env.NODE_ENV}`);

initSentry();

ReactDOM.render(<VZApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
