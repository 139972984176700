import React, { useState, useEffect, useContext } from 'react';

import IVZTeamRole from 'interfaces/teamRole';
import { VZModal, ToastContext, UserContext } from 'vzc-client-common';
import IVZTeamPackage from 'interfaces/teamPackage';
import { authAPI, parseErrorObject, combineClassNames, removeAtIndex } from 'vzc-client-common';
import { TeamContext } from 'dashboard/shared/team-context/index';
import VZActionButton from 'dashboard/shared/action-button';

import style from './index.module.scss';
import VZSearchBar from 'dashboard/shared/search-bar';
import { VZBillingType } from 'interfaces/team';

type IVZProps = {
    close: () => void;
    onSubmitted: () => void;
    role: IVZTeamRole;
};

export default function VZModifyRolePackagesModal(props: IVZProps) {
    const teamContext = useContext(TeamContext);
    const toastContext = useContext(ToastContext);
    const userContext = useContext(UserContext);

    const [teamPackages, setTeamPackages] = useState<IVZTeamPackage[]>([]);
    const [selectedPackages, setSelectedPackages] = useState(props.role.packages.map((p) => p._id));
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        loadTeamPackages();
    }, []);

    return (
        <VZModal title={`Modify Packages for '${props.role.name}' Role`} closeModal={props.close}>
            <div className={style.msg}>
                This role contains {props.role.users.length} members. Editing packages to this role will affect each members access to the packages.
            </div>

            <VZSearchBar search={search} onSearchChanged={setSearch} searchBarClassName={style.searchBar} />
            <div className={style.scrollContainer}>
                <div>{renderTeamPackages()}</div>
            </div>

            <VZActionButton label="Save" styleType="ORANGE" disabled={isSubmitting || isLoading} onClick={save} />
        </VZModal>
    );

    function renderTeamPackages() {
        const billingType = teamContext.currentTeam!.billingType;

        return teamPackages
            .filter((teamPackage) => search === '' || teamPackage.packageName.match(search))
            .map((teamPackage) => {
                const packageSelectedIndex = selectedPackages.findIndex((sp) => sp === teamPackage.package);
                const isSelected = packageSelectedIndex !== -1;
                const buttonText = isSelected ? 'Package Assigned' : 'Assign Package';
                const onPackageSelected = () => {
                    if (isSelected) {
                        setSelectedPackages(removeAtIndex(selectedPackages, packageSelectedIndex));
                    } else {
                        setSelectedPackages([...selectedPackages, teamPackage.package]);
                    }
                };

                return (
                    <div key={teamPackage.package} className={style.teamPackage}>
                        <div className={style.name}>
                            {teamPackage.packageName}
                            {billingType === VZBillingType.PO && (
                                <React.Fragment>
                                    <br></br>
                                    <span className={style.purchased}>{teamPackage.totalQuantity} Purchased</span>
                                </React.Fragment>
                            )}
                        </div>

                        {billingType === VZBillingType.DYNAMIC && <div className={style.dynamicUsage}>{teamPackage.usedQuantity} users</div>}

                        {billingType === VZBillingType.DYNAMIC ? (
                            <div className={style.usage}>${teamPackage.costPerMonth}/mo</div>
                        ) : (
                            <div className={style.usage}>
                                {teamPackage.usedQuantity}/{teamPackage.totalQuantity}
                            </div>
                        )}

                        <div
                            className={combineClassNames(style.packageAssignedButton, isSelected ? style.assigned : style.notAssigned)}
                            onClick={onPackageSelected}
                        >
                            {buttonText}
                        </div>

                        {billingType === VZBillingType.PO && (
                            <div className={style.available}>
                                <span className={style.count}>{teamPackage.remainingQuantity}</span>
                                <br></br>
                                <span className={style.message}>Packages Available</span>
                            </div>
                        )}
                    </div>
                );
            });
    }

    async function loadTeamPackages() {
        if (!userContext.authToken) {
            return;
        }

        try {
            setIsLoading(true);

            if (teamContext.currentTeam) {
                const teamPackages = await authAPI.packages.getTeamPackages(userContext.authToken, teamContext.currentTeam._id);
                setTeamPackages(teamPackages);
            }
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
            setIsLoading(false);
        }
    }

    async function save() {
        if (!userContext.authToken) {
            return;
        }

        try {
            setIsSubmitting(true);

            if (teamContext.currentTeam) {
                const teamPackages = await authAPI.roles.modifyRolePackages(userContext.authToken, props.role._id, selectedPackages);
                props.close();
                props.onSubmitted();
            }
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
            setIsSubmitting(false);
        }
    }
}
