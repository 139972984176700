import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
    VZModal,
    VZDropdown,
    UserContext,
    VZActionButton,
    VZCompanyAuthType,
    authAPI,
    parseErrorObject,
    ToastContext,
    coreAPI,
    IVZConnection,
    VZConnectionAuthType,
    useLocale,
} from 'vzc-client-common';
import VZButtonSmall from 'dashboard/shared/button-small';
import VZMultiToggle from 'dashboard/shared/multi-toggle-button';

import downloadImg from './download.svg';
import downloadDisabledImg from './download-disabled.svg';
import refreshImg from './refresh.svg';
import refreshDisabledImg from './refresh-disabled.svg';
import warningImg from './warning.svg';
import errorImg from './error.svg';
import { VZLoadingArea } from 'dashboard/shared/loading-area';
import { VZPrompt } from 'shared/VZPrompt';

interface IVZConnectionEditProps {
    close: () => void;
    connection: IVZConnection;
}
//Dummy data
const AUTH_TYPE_OPTIONS = [VZConnectionAuthType.BASIC, VZConnectionAuthType.JWT];
enum EXPIRY_STATES {
    WARNING = 'WARNING',
    EXPIRED = 'EXPIRED',
    ACTIVE = 'ACTIVE',
}

const documentationURL = 'https://support.oracle.com/epmos/faces/DocumentDisplay?parent=SrDetailText&sourceId=3-22768023641&id=2572018.1';

export default function VZManageConnectionModal(props: IVZConnectionEditProps) {
    const locale = useLocale();
    const userContext = useContext(UserContext);
    const toastContext = useContext(ToastContext);
    const [connection, setConnection] = useState(props.connection);
    const [errorMsg, setErrorMsg] = useState('');
    const [selectedAuthType, setSelectedAuthType] = useState<string>(props.connection.authType);
    const expireDate = connection.jwtSigningCertExpireDate != null ? moment(connection.jwtSigningCertExpireDate).format('YYYY-MM-DD HH:mm:ss') : '';
    const [isLoading, setIsLoading] = useState(false);
    const [showGenerateCertPrompt, setShowGenerateCertPrompt] = useState(false);
    const today = moment().startOf('day');
    const dateDiff = expireDate != null && moment(expireDate).diff(today, 'days');
    //Gives a warning if the certificate expiring in 15 days from current date.
    const expiryStatus = dateDiff < 16 && dateDiff > 0 ? EXPIRY_STATES.WARNING : dateDiff < 0 ? EXPIRY_STATES.EXPIRED : EXPIRY_STATES.ACTIVE;

    const hasSigningCert = connection.jwtSigningPublicKeyObjectKey != null;

    // useEffect(() => {
    //     loadData();
    // }, [])

    return (
        <VZModal title="Manage Connection Authentication" closeModal={props.close}>
            <VZLoadingArea isLoading={isLoading}>
                <ModalContainer>
                    {showGenerateCertPrompt && renderGenerateCertPrompt()}
                    <AuthTypeContainer>
                        <Label>AUTHENTICATION TYPE</Label>
                        <VZMultiToggle options={AUTH_TYPE_OPTIONS} selectedOption={selectedAuthType} setSelectedOption={setSelectedAuthType} />
                    </AuthTypeContainer>
                    <ActionList>
                        {hasSigningCert && (
                            <Action>
                                <ActionTitleContainer>
                                    <Label>{renderExpireLabelIcon()} DOWNLOAD SIGNING CERTIFICATE</Label>
                                    <VZButtonSmall
                                        imgSrc={downloadImg}
                                        disabledImgSrc={downloadDisabledImg}
                                        disabled={false}
                                        onClick={downloadCert}
                                    />
                                </ActionTitleContainer>
                                <ActionDescription>
                                    Attach this Certificate to an Oracle Cloud SR against every POD that requires JWT-based authentication.
                                    <Link href={documentationURL} target="_blank">
                                        Read more in the Oracle KB article.
                                    </Link>
                                </ActionDescription>
                                <br />
                                <ActionDescription>Issuer: {connection.issuer}</ActionDescription>
                                <ActionDescription>This certificate expires on {expireDate}</ActionDescription>
                            </Action>
                        )}
                        {expiryStatus === EXPIRY_STATES.WARNING && renderWarningPanel()}
                        {expiryStatus === EXPIRY_STATES.EXPIRED && renderExpiredPanel()}
                        <Line />
                        <Action>
                            <ActionTitleContainer>
                                <Label>GENERATE A NEW CERTIFICATE</Label>
                                <VZButtonSmall
                                    imgSrc={refreshImg}
                                    disabledImgSrc={refreshDisabledImg}
                                    disabled={false}
                                    onClick={() => {
                                        setShowGenerateCertPrompt(true);
                                    }}
                                />
                            </ActionTitleContainer>
                            <ActionDescription>
                                Warning: Generating a new Certificate will invalidate the existing Certificate. Login will not be possible until a SR
                                is raised with Oracle containing tthe Certificate. Read more in the Oracle KB article.
                                <Link href={documentationURL} target="_blank">
                                    Read more in the Oracle KB article.
                                </Link>
                            </ActionDescription>
                        </Action>
                    </ActionList>
                    <Button label="SAVE" styleType="ORANGE" onClick={save} />
                </ModalContainer>
            </VZLoadingArea>
        </VZModal>
    );

    function renderGenerateCertPrompt() {
        return (
            <VZPrompt
                title="Generate New Cert?"
                message="Are you sure you want to generate a new cert?"
                okText={locale.formatMessage('prompt.yes')}
                onOk={() => generateCert()}
                onClose={() => setShowGenerateCertPrompt(false)}
                cancelText={locale.formatMessage('prompt.no')}
                depth={1000}
            />
        );
    }

    async function generateCert() {
        try {
            setIsLoading(true);

            if (userContext.authToken && userContext.companyId) {
                await coreAPI.connections.generateJWTSigningCertificate(userContext.authToken, props.connection._id);
            }
        } catch (err) {
            const errorObject = parseErrorObject(err);
            toastContext.showToast(`${errorObject.type}: ${errorObject.message}`, 'ERROR');
        } finally {
            setIsLoading(false);

            reloadData();
        }
    }

    async function save() {
        try {
            setIsLoading(true);

            if (userContext.authToken && userContext.companyId) {
                await coreAPI.connections.editConnection(userContext.authToken, props.connection.team, props.connection._id, {
                    authType: selectedAuthType as VZConnectionAuthType,
                });
                props.close();
            }
        } catch (err) {
            const errorObject = parseErrorObject(err);
            toastContext.showToast(`${errorObject.type}: ${errorObject.message}`, 'ERROR');
        } finally {
            setIsLoading(false);
        }
    }

    async function reloadData() {
        try {
            setIsLoading(true);

            if (userContext.authToken && userContext.companyId) {
                const newConnection = await coreAPI.connections.getConnection(userContext.authToken, props.connection._id);
                setConnection(newConnection);
            }
        } catch (err) {
            const errorObject = parseErrorObject(err);
            toastContext.showToast(`${errorObject.type}: ${errorObject.message}`, 'ERROR');
        } finally {
            setIsLoading(false);
        }
    }

    async function downloadCert() {
        if (!userContext.authToken) {
            return;
        }

        try {
            setIsLoading(true);

            const base64Cert = await coreAPI.connections.downloadJWTSigningCertificate(userContext.authToken, props.connection._id);
            downloadFile('client.pem', new Buffer(base64Cert, 'base64').toString('utf-8'), 'application/cer');
            toastContext.showToast('Downloaded JWT Signing Certificate', 'SUCCESS');
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
            setIsLoading(false);
        }
    }

    function downloadFile(fileName: string, fileStr: string, fileType: string) {
        let a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(new File([fileStr], fileName, { type: fileType }));
        a.click();
    }

    // async function loadData() {
    //     try {
    //         if (userContext.authToken && userContext.companyId) {
    //             const { serviceProviderCertExpireDate, authType } = await coreAPI.connections.getConnection(userContext.authToken, userContext.companyId);
    //             if (serviceProviderCertExpireDate) {
    //                 setExpireDate(moment(serviceProviderCertExpireDate).format('YYYY-MM-DD'));
    //             }
    //             if (authType) {
    //                 setSelectedAuthType(authType === 'SSO' ? AUTH_TYPE_OPTIONS[1] : AUTH_TYPE_OPTIONS[0])
    //             }
    //         }
    //     } catch (err) {
    //         const errorObject = parseErrorObject(err);
    //         toastContext.showToast(`${errorObject.type}: ${errorObject.message}`, 'ERROR');
    //     }
    // }

    function renderExpireLabelIcon() {
        const statusImg = expiryStatus === EXPIRY_STATES.WARNING ? warningImg : expiryStatus === EXPIRY_STATES.EXPIRED ? errorImg : null;
        if (statusImg) {
            return <ExpiryImage src={statusImg} alt="status" />;
        }

        return;
    }
    function renderWarningPanel() {
        return (
            <WarningContainer>
                <WarningText>
                    <b>Warning: Certificate will expire on {expireDate != null && moment(expireDate).format('YYYY-MM-DD')}.</b> You must create a new
                    one:
                </WarningText>
                <WarningText>1. Generate a new certificate below</WarningText>
                <WarningText>2. Download your new signing certificate</WarningText>
                <WarningText>3. Create an Oracle SR against every POD that requires JWT-based authentication</WarningText>
                <WarningText>
                    <Link>Read more in the Oracle KB Article.</Link>
                </WarningText>
            </WarningContainer>
        );
    }

    function renderExpiredPanel() {
        return (
            <ExpiredContainer>
                <WarningText>
                    <b>Warning: Certificate expired on {expireDate != null && moment(expireDate).format('YYYY-MM-DD')}.</b> You must create a new one:
                </WarningText>
                <WarningText>1. Generate a new certificate below</WarningText>
                <WarningText>2. Download your new signing certificate</WarningText>
                <WarningText>3. Create an Oracle SR against every POD that requires JWT-based authentication</WarningText>
                <WarningText>
                    <Link>Read more in the Oracle KB Article.</Link>
                </WarningText>
            </ExpiredContainer>
        );
    }
}

const Button = styled(VZActionButton)`
    width: 150px;
    height: 35px;
    margin-top: 25px;
    border-radius: 3px;
    align-self: center;
`;

const ExpiryImage = styled.img<any>`
    margin-right: 8px;
`;
const WarningText = styled.span`
    font-size: 10px;
    color: #555555;
`;
const ExpiryContainer = styled.div`
    display: flex;
    margin: 20px 0 20px 0;
    padding: 20px;
    flex-flow: column nowrap;
    border-radius: 5px;
    background-color: #ffffff;
`;
const WarningContainer = styled(ExpiryContainer)`
    border: solid 1px #e8b800;
`;
const ExpiredContainer = styled(ExpiryContainer)`
    border: solid 1px #de3046;
`;

const Line = styled.div`
    flex: 1;
    border-bottom: 1px solid #cccccc;
    margin: 20px 0 20px 0;
`;
const ActionTitleContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
`;
const ActionDescription = styled.span`
    font-size: 12px;
    color: #9b9b9b;
    max-width: 475px;
`;

const Link = styled.a`
    color: #ed8b00;
    text-decoration: underline;
    :hover {
        cursor: pointer;
    }
`;

const Action = styled.div`
    display: flex;
    flex-flow: column nowrap;
`;

const ActionList = styled.div<any>`
    display: flex;
    flex-flow: column nowrap;
    ${({ disabled }) =>
        disabled &&
        `
        span {
            color: #9b9b9b;
        }
        
    `}
`;

const Label = styled.span`
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 12px;
    font-weight: 600;
    color: #555555;
`;

const AuthTypeContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 30px;
`;

const ModalContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin: 10px;
    margin-top: 20px;
`;
