import React, { Component, CSSProperties } from 'react';

import style from './index.module.scss';

import { ReactComponent as SearchIcon } from './search.svg';
import { combineClassNames, overrideClassName } from 'vzc-client-common';
import VZLoadingIndicator from 'dashboard/shared/loading-indicator';

interface IVZSearchBarProps {
    isLoading?: boolean;
    suggestions?: string[];
    placeholder?: string;
    searchBarClassName?: string;
    inputClassName?: string;
    buttonClassName?: string;
    loadingClassName?: string;
    search: string;
    onSearchChanged: (newValue: string) => void;
    onSubmit?: () => void;
    onSuggestionSelected?: (suggestion: string) => void;
}

export default function VZSearchBar(props: IVZSearchBarProps) {
    return (
        <div className={combineClassNames(style.searchBar, props.searchBarClassName)}>
            <SearchIcon className={combineClassNames(style.searchButton, props.buttonClassName)} onClick={props.onSubmit} />
            <input
                placeholder={props.placeholder}
                className={combineClassNames(style.searchInput, props.inputClassName)}
                value={props.search}
                onChange={onSearchChanged}
                onKeyUp={onInputKeyUp}
            ></input>
            {props.isLoading && <VZLoadingIndicator className={overrideClassName(style.loadingIndicator, props.loadingClassName)} />}
            {props.suggestions && <div className={style.suggestionBox}>{renderSuggestions()}</div>}
        </div>
    );

    function onSearchChanged(e: React.ChangeEvent<HTMLInputElement>) {
        props.onSearchChanged(e.target.value);
    }

    function onInputKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.keyCode === 13 && props.onSubmit) {
            props.onSubmit();
        }
    }

    function renderSuggestions() {
        return props.suggestions!.map((suggestion) => {
            return (
                <div className={style.suggestion} onClick={() => props.onSuggestionSelected!(suggestion)}>
                    {suggestion}
                </div>
            );
        });
    }
}
