import React, { Component, useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, match } from 'react-router-dom';
import { Provider } from 'react-redux';

import ReactGA from 'react-ga';

import VzSignUp from './platform/signup';
import VZSignUpConfirmation from './platform/signUpConfirmation';
import VZVerifyUser from './platform/verifyUser';
import vzLogout from './platform/logout';
import VZLogin from './platform/login';
import VZDashboard from 'dashboard';
import { Location } from 'history';
import qs from 'querystring';

import VzTopNavBar from './platform/top-navbar';
import VzFooter from './platform/footer';
import { VZUserContextProvider, VZLocalizationProvider, authAPI, coreAPI, VZToastProvider, ToastContext, UserContext } from 'vzc-client-common';

// import logo from './logo.svg';
import './App.scss';

import VZForgotPassword from './platform/forgot-password';
import VZResetPassword from './platform/reset-password/index';
import VZResetPasswordConfirm from './platform/reset-password-confirm';
import { envHelper } from 'vzc-client-common';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { defaultMessages } from 'shared/localization';

export default function VZApp() {
    if (!envHelper.isLocalhost()) {
        ReactGA.initialize('UA-36292686-3', {});
    }
    const basename = envHelper.isLocalhost() ? undefined : '/platform';
    return (
        <VZToastProvider>
            <VZLocalizationProvider defaultMessages={defaultMessages}>
                <VZUserContextProvider authAPI={authAPI} coreAPI={coreAPI}>
                    <Router basename={basename}>
                        <Switch>
                            <Route path="/dashboard" component={VZDashboard} />
                            <Route
                                path="/"
                                render={() => {
                                    return (
                                        <div className="App">
                                            <VzTopNavBar></VzTopNavBar>
                                            <div className="vzBodyContainer">
                                                <div className="vzContentContainer">
                                                    <Switch>
                                                        <Redirect exact={true} from="/" to="/login" />
                                                        <Route path="/signup" component={VzSignUp} />
                                                        <Route path="/forgot-password" component={VZForgotPassword} />
                                                        <Route path="/reset-password/:id/:verificationCode" component={VZResetPassword} />
                                                        <Route path="/signup-confirmation" component={VZSignUpConfirmation} />
                                                        <Route path="/password-reset-confirmation" component={VZResetPasswordConfirm} />
                                                        <Route path="/confirm-email/:userId/:verificationCode" component={VZVerifyUser} />
                                                        <Route path="/logout" component={vzLogout} />
                                                        <Route path="/login" component={VZLogin} />
                                                        <Route path="/sso_post_login" component={VZSSOPostLogin} />
                                                    </Switch>
                                                </div>
                                            </div>
                                            <VzFooter></VzFooter>
                                        </div>
                                    );
                                }}
                            />
                        </Switch>
                    </Router>
                </VZUserContextProvider>
            </VZLocalizationProvider>
        </VZToastProvider>
    );
}

function VZSSOPostLogin(props: { location: Location }) {
    const toastContext = useContext(ToastContext);
    const userContext = useContext(UserContext);

    const [redirect, setRedirect] = useState<string | null>(null);

    useEffect(() => {
        if (props.location.search !== '') {
            const token = qs.parse(props.location.search.substr(1))['token'] as string;
            userContext.setAuthFromToken(token);

            setRedirect('/dashboard');
        } else {
            toastContext.showError('Expected token!');
        }
    }, []);

    if (redirect) {
        return <Redirect to={redirect}></Redirect>;
    } else {
        return <div></div>;
    }
}
