import React, { Fragment } from 'react';
import styled from 'styled-components';

interface MultiToggleProps {
    options: string[];
    selectedOption: string;
    setSelectedOption: (newOption: string) => void;
}
export default function VZMultiToggle(props: MultiToggleProps) {
    const { options, selectedOption, setSelectedOption } = props;
    return (
        <div>
            <MultiToggleContainer>
                {options.map((option) => (
                    <Toggle key={option} selected={selectedOption === option} onClick={() => setSelectedOption(option)}>
                        {option}
                    </Toggle>
                ))}
            </MultiToggleContainer>
        </div>
    );
}

const MultiToggleContainer = styled.div`
    display: inline-flex;
    flex-flow: row nowrap;
    height: 40px;
    border-radius: 5px;
    border-left: solid 1px #9797974d;
    border-top: solid 1px #9797974d;
    border-bottom: solid 1px #9797974d;
    font-size: 12px;
`;

const Toggle = styled.div<any>`
    display: flex;
    min-width: 100px;
    padding: 0 5px 0 5px;
    background-color: ${({ selected }) => (selected ? '#ffffff' : '#f5f5f6')};
    color: ${({ selected }) => (selected ? '#4a4a4a' : '#cccccc')};
    justify-content: center;
    align-items: center;
    border-right: solid 1px #9797974d;
    border-radius: 5px;
    :hover {
        cursor: ${({ selected }) => (selected ? 'auto' : 'pointer')};
    }
`;
