import React from 'react';

import style from './index.module.scss';
import editImg from './edit.svg';

type IVZProps = {
    label: string;
    onEditClick?: () => void;
};

export default function VZLabelWithEditButton(props: IVZProps) {
    return (
        <div className={style.bar}>
            <div className={style.label}>{props.label}</div>
            <img className={style.edit} src={editImg} onClick={props.onEditClick} />
        </div>
    );
}
