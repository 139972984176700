import React, { useState, useContext } from 'react';

import VZActionButton from 'dashboard/shared/action-button';
import IVZConnection from 'interfaces/connection';
import { coreAPI, envHelper } from 'vzc-client-common';
import VZConnectionAddModal from './connection-add-modal';
import VZConnectionEditModal from './connection-edit-modal';

import style from './index.module.scss';
import oracleImg from './oracle-logo.png';
import { ToastContext, UserContext, useLocale } from 'vzc-client-common';
import VZRowButton, { VZRowButtonType } from 'dashboard/shared/row-button';
import VZDashboardTable, { useDashboardTable, IVZDashboardTableColumn } from 'dashboard/shared/dashboard-table';
import { TeamContext } from 'dashboard/shared/team-context';
import constants from 'utility/constants';
import VZManageConnectionModal from './manage-connection-modal';
import { VZLoadingArea } from 'dashboard/shared/loading-area';
import { VZPrompt } from 'shared/VZPrompt';
import styled from 'styled-components';

const connectionTypeImages: { [index: string]: string } = {
    ORACLE_CLOUD: oracleImg,
};

export default function VZConnectionsTab() {
    const locale = useLocale();

    const userContext = useContext(UserContext);
    const toastContext = useContext(ToastContext);
    const teamContext = useContext(TeamContext);

    const connectionColumns: IVZDashboardTableColumn<IVZConnection>[] = [
        {
            columnId: 'NAME',
            label: locale.formatMessage('dashboard.teams.connections.name'),
            width: 300,
            render: (connection, classes) => {
                return <div className={classes.nameLabel}>{connection.name}</div>;
            },
        },
        {
            columnId: 'SHORT_NAME',
            label: locale.formatMessage('dashboard.teams.connections.short_name'),
            width: 300,
            render: (connection, classes) => {
                return <div className={classes.nameLabel}>{connection.shortName}</div>;
            },
        },
        {
            columnId: 'TYPE',
            label: locale.formatMessage('dashboard.teams.connections.type'),
            width: 200,
            render: (connection) => {
                return <img src={connectionTypeImages[connection.ERP]} />;
            },
        },
        {
            columnId: 'ACTIONS',
            label: locale.formatMessage('dashboard.list.actions'),
            width: 200,
            minWidth: 205,
            flexGrow: 1,
            render: (connection, classes) => {
                return (
                    <div className={classes.actions}>
                        <VZRowButton actionType={VZRowButtonType.ENTER} onClick={() => openConnection(connection)} />
                        {teamContext.isAdminOfCurrentTeam() && (
                            <VZRowButton actionType={VZRowButtonType.EDIT} onClick={() => openEditModal(connection)} />
                        )}
                        {teamContext.isAdminOfCurrentTeam() && (
                            <VZRowButton
                                actionType={VZRowButtonType.EDIT_CONNECTION}
                                onClick={() => {
                                    setShowManageConnectionModal(!showManageConnectionModal);
                                    setEditConnection(connection);
                                }}
                            />
                        )}
                        {teamContext.isAdminOfCurrentTeam() && (
                            <VZRowButton actionType={VZRowButtonType.DELETE} onClick={() => setConnectionToDelete(connection)} />
                        )}
                    </div>
                );
            },
        },
    ];

    const tableProps = useDashboardTable(connectionColumns, fetchConnections, [teamContext.currentTeam]);

    const [showConnectionsAdd, setShowConnectionsAdd] = useState(false);
    const [showConnectionsEdit, setShowConnectionsEdit] = useState(false);
    const [isLoadingConnection, setIsLoadingConnection] = useState(false);
    const [editConnection, setEditConnection] = useState<IVZConnection | null>(null);
    const [connectionToDelete, setConnectionToDelete] = useState<IVZConnection | null>(null);
    const [showManageConnectionModal, setShowManageConnectionModal] = useState(false);

    return (
        <div className={style.tab}>
            <VZLoadingArea isLoading={isLoadingConnection}>
                {showManageConnectionModal && editConnection && (
                    <VZManageConnectionModal
                        connection={editConnection}
                        close={() => {
                            setShowManageConnectionModal(false);
                            tableProps.refresh();
                        }}
                    />
                )}
                {showConnectionsAdd && (
                    <VZConnectionAddModal show={showConnectionsAdd} close={() => setShowConnectionsAdd(false)} submitted={tableProps.refresh} />
                )}

                {showConnectionsEdit && editConnection && (
                    <VZConnectionEditModal
                        connectionToEdit={editConnection}
                        show={showConnectionsEdit}
                        close={() => setShowConnectionsEdit(false)}
                        submitted={tableProps.refresh}
                    />
                )}

                {connectionToDelete && (
                    <VZPrompt
                        title={locale.formatMessage('dashboard.teams.delete_connection.title')}
                        okText={'Ok'}
                        onOk={() => setConnectionToDelete(null)}
                        cancelText={'Cancel'}
                        children={
                            <DeletePromptContainer>
                                <span>Please raise a Support ticket with the following information:</span>
                                <DeletePromptText>{`This is a Request to delete Connection {${connectionToDelete.name}} (#${connectionToDelete._id})`}</DeletePromptText>
                            </DeletePromptContainer>
                        }
                        onClose={() => setConnectionToDelete(null)}
                    />
                )}

                <div className={style.buttonContainer}>
                    {teamContext.isAdminOfCurrentTeam() && (
                        <VZActionButton
                            className={style.actionButton}
                            label={locale.formatMessage('dashboard.teams.add_connection')}
                            styleType="GREY"
                            onClick={() => setShowConnectionsAdd(true)}
                        />
                    )}
                </div>

                <VZDashboardTable
                    searchBarPlaceholder={locale.formatMessage('dashboard.teams.connections.search')}
                    countSuffix={'dashboard.teams.connections.count'}
                    {...tableProps}
                />
            </VZLoadingArea>
        </div>
    );

    async function fetchConnections(search: string) {
        if (userContext.authToken && teamContext.currentTeam) {
            return await coreAPI.connections.getConnectionsInTeam(userContext.authToken, teamContext.currentTeam._id, search);
        } else {
            return [];
        }
    }

    async function openConnection(connection: IVZConnection) {
        let search = '';

        if (envHelper.getEnvVar(constants.PUT_JWT_IN_QUERY_STRING_KEY) === '1') {
            search = `?authToken=${userContext.authToken}`;
        }

        let newWindow: Window | null = null;
        if (envHelper.isLocalhost()) {
            // In Localhost, use absolute URL (Makes testing easier).
            newWindow = window.open(`${envHelper.getEnvVar(constants.WORKALIGN_ORACLE_URI_KEY)}/${connection._id}/login${search}`, '_blank');
        } else {
            // In non-localhost, Application is relative to current domain.
            const url = envHelper.createRelativeUrl(`workalignoracle/${connection._id}/login${search}`);
            newWindow = window.open(url, '_blank');
        }

        // We check if the user still has access after clicking. We can't do the check before because awaiting the REST calls stops it being a trusted event.
        // Link to issue: https://stackoverflow.com/questions/22007592/chrome-window-open-after-ajax-request-acts-like-popup
        const hasAccessToConnection = await checkConnection(connection);
        if (hasAccessToConnection === false) {
            // Warn user their session has ended and close the newly opened tab
            toastContext.showError('You can no longer enter that connection!');
            newWindow && newWindow.close();
        }
    }

    /** Checks if we still have */
    async function checkConnection(connection: IVZConnection) {
        try {
            setIsLoadingConnection(true);

            if (userContext.authToken != null) {
                const connectionFromServer = await coreAPI.connections.getConnection(userContext.authToken, connection._id);
                return connectionFromServer != null;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        } finally {
            setIsLoadingConnection(false);
        }
    }

    function openEditModal(connection: IVZConnection) {
        setShowConnectionsEdit(true);
        setEditConnection(connection);
    }

    async function deleteConnection(connection: IVZConnection) {
        if (!userContext.authToken) {
            return;
        }

        try {
            await coreAPI.connections.deleteConnection(userContext.authToken, connection._id);

            setConnectionToDelete(null);

            tableProps.refresh();

            toastContext.showToast('Connection deleted!');
        } catch (err) {
            console.log(err);
        }
    }
}

const DeletePromptContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    font-size: 13px;
    color: #555555;
`;

const DeletePromptText = styled.span`
    margin-top: 10px;
    font-weight: 700;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
`;
