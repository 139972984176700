import React, { Fragment, useContext } from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components';

interface IVZProps {
    className?: string;
    title: string;
    message?: string;
    okText: string;
    cancelText?: string;
    depth?: number;
    onOk?: () => void;
    onClose?: () => void;
    children?: React.ReactNode;
}

export function VZPrompt(props: IVZProps) {
    let modalContainer = document.querySelector('#modal');
    const depth = props.depth ? props.depth : 1;
    if (modalContainer) {
        return ReactDOM.createPortal(
            <Fragment>
                <BackgroundFilter zIndex={depth} onClick={props.onClose} />
                <Prompt className={props.className} zIndex={depth + 1}>
                    <Title>{props.title}</Title>
                    <Line />
                    {props.children ? <ChildContainer>{props.children}</ChildContainer> : <Message>{props.message}</Message>}
                    <ButtonContainer>
                        <CancelButton onClick={props.onClose}>{props.cancelText}</CancelButton>
                        <OkButton onClick={onOk}>{props.okText}</OkButton>
                    </ButtonContainer>
                </Prompt>
            </Fragment>,
            modalContainer
        );
    } else {
        console.error('Could not mount modal, missing element #modal!');
        return null;
    }

    function onOk() {
        props.onOk && props.onOk();
        props.onClose && props.onClose();
    }
}

const BackgroundFilter = styled.div<{ zIndex: number }>`
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: ${(props) => props.zIndex};
    background-color: #000000;
    opacity: 0.5;
`;

const Prompt = styled.div<{ zIndex: number }>`
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    z-index: ${(props) => props.zIndex + 1};
    top: calc(50vh);
    left: calc(50vw);
    transform: translateX(-50%) translateY(-50%);
    width: 400px;
    min-height: 155px;
    border-radius: 3px;
    border: solid 0.5px rgba(74, 74, 74, 0.25);
    background-color: #ffffff;
`;

const Title = styled.h3`
    font-size: 15px;
    font-weight: 500;
    text-align: center;
`;

const Line = styled.div`
    height: 0px;
    border: solid 0.5px rgba(151, 151, 151, 0.2);
`;

const Message = styled.h4`
    text-align: center;
    font-weight: 400;
    font-size: 13px;
`;

const ChildContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
    padding: 10px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const Button = styled.button`
    font-size: 12px;
    font-weight: 500;
    height: 30px;
    border-radius: 3px;
    border-color: transparent;
    padding: 0 20px;
    margin-right: 15px;
`;

const OkButton = styled(Button)`
    color: #f5f5f6;
    background-color: #ed8b00;
    cursor: pointer;
`;

const CancelButton = styled(Button)`
    background-color: #f5f5f6;
    border-radius: 3px;
    color: #676666;
    cursor: pointer;
    margin-left: auto;
`;
