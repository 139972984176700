import React from 'react';
import styled from 'styled-components';

//Toggle component
interface IVZToggleProps {
    toggleValue: boolean;
    onToggleClick: () => void;
}
export default function VZToggle(props: IVZToggleProps) {
    const { toggleValue, onToggleClick } = props;
    return (
        <ToggleContainer toggleValue={toggleValue} onClick={() => onToggleClick()}>
            <Toggle toggleValue={toggleValue} />
        </ToggleContainer>
    );
}

const ToggleContainer = styled.div<any>`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 52px;
    height: 22px;
    border-radius: 100px;
    border: solid 1px #c5cee0;
    background-color: ${({ toggleValue }) => (toggleValue ? '#60bc5f' : 'rgba(230,230,230,1)')};
    padding: 1px;
    :hover {
        cursor: pointer;
    }
`;

const Toggle = styled.div<any>`
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: #ffffff;
    transition: 0.25s;
    left: ${({ toggleValue }) => (toggleValue ? '30px' : '2px')};
`;
