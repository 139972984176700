import React from 'react';

import { Link } from 'react-router-dom';
import VzMenuItem from '../navbar-item';

import navbarLogo from './headerLogo.svg';
import menuIcon from './_ionicons_svg_md-menu.svg';

import './index.scss';

type VzNavProps = {
    // using `interface` is also ok
};

type VzNavState = {
    location: string; // like this
    expanded: boolean;
};

export class VzTopNavBar extends React.Component<VzNavProps, VzNavState> {
    constructor(props: VzNavProps) {
        super(props);

        this.state = {
            location: '/',
            expanded: false,
        };

        this.changeRoute = this.changeRoute.bind(this);
        this.showMenu = this.showMenu.bind(this);
    }

    render() {
        return (
            <header className="vzNavbar">
                <div className="vzNavbar__Link vzNavbar__Link-toggle" onClick={this.showMenu}>
                    <img src={menuIcon} className="vzNavbarMenuIcon"></img>
                </div>
                <div className="vzNavbar__Link-brand">
                    <Link to="/">
                        <img src={navbarLogo} className="vzNavbarLogo" alt="Viziya Cloud Logo" />
                    </Link>
                </div>
                <nav className={this.state.expanded ? 'vzNavbar__Items__Show vzNavbar__Items--right' : 'vzNavbar__Items vzNavbar__Items--right'}>
                    {/* <VzMenuItem route="/pricing">Pricing</VzMenuItem>
          <VzMenuItem route="how-we-work">How We Work</VzMenuItem>
          <VzMenuItem route="/who-we-are">Who We Are</VzMenuItem> */}
                    {localStorage.getItem('token') ? (
                        <VzMenuItem route="/dashboard">Dashboard</VzMenuItem>
                    ) : (
                        <VzMenuItem route="/login">Login</VzMenuItem>
                    )}

                    {localStorage.getItem('token') ? (
                        <VzMenuItem orange route="/logout">
                            Logout
                        </VzMenuItem>
                    ) : (
                        <VzMenuItem orange route="/signup">
                            Sign Up
                        </VzMenuItem>
                    )}
                </nav>
            </header>
        );
    }

    changeRoute = (rte: string) => {
        this.setState({ location: rte });
    };

    showMenu = () => {
        this.setState({ expanded: !this.state.expanded });
    };
}

export default VzTopNavBar;
