import React, { useState, useContext } from 'react';
import { VZModal, VZInput, ToastContext, UserContext } from 'vzc-client-common';

import style from './index.module.scss';
import { combineClassNames, authAPI, parseErrorObject } from 'vzc-client-common';
import VZActionButton from 'dashboard/shared/action-button';
import useForm from 'dashboard/shared/useForm';
import { TeamContext } from 'dashboard/shared/team-context';

export function VZAddRoleModal(props: { close: () => void }) {
    const userContext = useContext(UserContext);
    const teamContext = useContext(TeamContext);
    const toastContext = useContext(ToastContext);

    const [errorMsg, setErrorMsg] = useState('');
    const [form, fields] = useForm(
        {
            name: '',
        },
        {
            name: {
                type: 'string',
                required: true,
                minLength: 5,
                maxLength: 20,
            },
        },
        createRole
    );

    return (
        <VZModal title="Add Role" closeModal={props.close}>
            <label className={style.formLabel}>NAME</label>
            <VZInput
                type="text"
                className={combineClassNames(style.formTextInput, fields.name.error && style.error)}
                placeholder="ENTER NAME"
                value={fields.name.value}
                setValue={fields.name.setValue}
            />
            {fields.name.error && <p className={style.formError}>{fields.name.error}</p>}

            <VZActionButton
                label="Add Role"
                styleType="ORANGE"
                className={style.saveButton}
                disabled={!form.isValid && !form.isSubmitting}
                onClick={form.submit}
            />

            {errorMsg != '' && <p className={style.formError}>{errorMsg}</p>}
            {form.isSubmitting && <p className={style.formStatus}>Creating...</p>}
        </VZModal>
    );

    async function createRole() {
        if (teamContext.currentTeam && userContext.authToken) {
            try {
                await authAPI.roles.createRole(userContext.authToken, teamContext.currentTeam._id, fields.name.value);

                props.close();
            } catch (err) {
                const errorObj = parseErrorObject(err);
                setErrorMsg(`${errorObj.type}: ${errorObj.message}`);
            }
        }
    }
}
