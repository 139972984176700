import React, { useContext, useState } from 'react';

import { TeamContext } from 'dashboard/shared/team-context';

import style from './index.module.scss';
import VZActionButton from 'dashboard/shared/action-button';
import { VZAddRoleModal } from './add-role-modal';
import VZTabbedContentContainer, { IVZTab } from 'dashboard/shared/tabbed-content-container';
import VZRoleList from './role-list/ndex';
import VZPackageList from './package-list/index';

export default function VZRolesTab() {
    const teamContext = useContext(TeamContext);
    const [showCreateRole, setShowCreateRole] = useState(false);
    const [currentTabId, setCurrentTabId] = useState('ROLES');

    const tabs: IVZTab[] = [
        {
            id: 'ROLES',
            label: 'Roles',
            render: renderRoles,
        },
        {
            id: 'PACKAGES',
            label: 'Packages',
            render: renderPackages,
        },
    ];

    return (
        <div className={style.tab}>
            {showCreateRole && <VZAddRoleModal close={() => setShowCreateRole(false)} />}

            <VZTabbedContentContainer
                tabs={tabs}
                currentTabId={currentTabId}
                setCurrentTabId={setCurrentTabId}
                renderMiscContent={renderMiscContent}
            />
        </div>
    );

    function renderRoles() {
        return <VZRoleList />;
    }

    function renderPackages() {
        return <VZPackageList />;
    }

    function renderMiscContent() {
        return (
            teamContext.isAdminOfCurrentTeam() && (
                <VZActionButton className={style.actionButton} label="ADD ROLE" styleType="GREY" onClick={() => setShowCreateRole(true)} />
            )
        );
    }
}
