import React, { useState, useEffect, useContext } from 'react';
import style from './index.module.scss';
import VZDashboardBox, { VZDashboardBoxRow, VZDashboardBoxColumn, boxStyle, VZDashboardBoxFooter } from 'dashboard/shared/dashboard-box';
import { IVZUserProfile, authAPI, formatMemorySize, uploadFileToS3 } from 'vzc-client-common';
import { VZInput, ToastContext, VZPrompt, UserContext, parseErrorObject } from 'vzc-client-common';
import { VZLoadingArea } from 'dashboard/shared/loading-area';
import VZActionButton from 'dashboard/shared/action-button';
import VZRowButton, { VZRowButtonType } from 'dashboard/shared/row-button';

export default function VZPersonalInfoTab() {
    const toastContext = useContext(ToastContext);
    const userContext = useContext(UserContext);

    const [profile, setProfile] = useState<IVZUserProfile | null>(null);
    const [isLoadingProfile, setIsLoadingProfile] = useState(false);
    const [isSavingProfile, setIsSavingProfile] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [title, setTitle] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [bio, setBio] = useState('');
    const [iconFile, setIconFile] = useState<File | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        loadProfile();
    }, []);

    useEffect(() => {
        if (profile) {
            setFirstName(profile.name.first);
            setLastName(profile.name.last);
            setTitle(profile.title || '');
            setPhoneNumber(profile.phoneNumber || '');
            setBio(profile.bio || '');
        }
    }, [profile]);

    const joinDate = profile ? new Date(profile.joinDate).toLocaleDateString() : '';

    return (
        <div className={style.tab}>
            <VZDashboardBox title="PERSONAL INFORMATION" miscLabel={profile ? `Joined on ${joinDate}` : ''}>
                <VZLoadingArea isLoading={isLoadingProfile || isSavingProfile}>
                    <VZDashboardBoxRow>
                        <VZDashboardBoxColumn>
                            <div className={boxStyle.label}>FIRST NAME</div>
                        </VZDashboardBoxColumn>

                        <VZDashboardBoxColumn>
                            <VZInput className={style.formTextInput} value={firstName} setValue={setFirstName} />
                        </VZDashboardBoxColumn>
                    </VZDashboardBoxRow>

                    <VZDashboardBoxRow>
                        <VZDashboardBoxColumn>
                            <div className={boxStyle.label}>LAST NAME</div>
                        </VZDashboardBoxColumn>

                        <VZDashboardBoxColumn>
                            <VZInput className={style.formTextInput} value={lastName} setValue={setLastName} />
                        </VZDashboardBoxColumn>
                    </VZDashboardBoxRow>

                    <VZDashboardBoxRow>
                        <VZDashboardBoxColumn>
                            <div className={boxStyle.label}>TITLE</div>
                        </VZDashboardBoxColumn>

                        <VZDashboardBoxColumn>
                            <VZInput className={style.formTextInput} value={title} setValue={setTitle} />
                        </VZDashboardBoxColumn>
                    </VZDashboardBoxRow>

                    <VZDashboardBoxRow>
                        <VZDashboardBoxColumn>
                            <div className={boxStyle.label}>PHONE NUMBER</div>
                        </VZDashboardBoxColumn>

                        <VZDashboardBoxColumn>
                            <VZInput className={style.formTextInput} value={phoneNumber} setValue={setPhoneNumber} />
                        </VZDashboardBoxColumn>
                    </VZDashboardBoxRow>

                    <VZDashboardBoxRow>
                        <VZDashboardBoxColumn>
                            <div className={boxStyle.label}>BIO</div>
                        </VZDashboardBoxColumn>

                        <VZDashboardBoxColumn>
                            <VZInput type="textarea" className={style.bio} value={bio} setValue={setBio} />
                        </VZDashboardBoxColumn>
                    </VZDashboardBoxRow>

                    <VZDashboardBoxRow>
                        <VZDashboardBoxColumn>
                            <div className={boxStyle.label}>Logo</div>
                        </VZDashboardBoxColumn>
                        <VZDashboardBoxColumn>
                            <img src={profile ? profile.iconUrl : undefined} className={style.logo} />
                            <input
                                type="file"
                                name="pic"
                                accept="image/png,image/jpg"
                                onChange={(e) => setIconFile(e.target.files && e.target.files[0])}
                            />
                            <div>{iconFile && iconFile.name}</div>
                        </VZDashboardBoxColumn>
                    </VZDashboardBoxRow>

                    <VZDashboardBoxFooter>
                        <VZDashboardBoxColumn>
                            <VZActionButton
                                styleType="ORANGE"
                                label="SAVE"
                                disabled={isLoadingProfile || isSavingProfile}
                                onClick={() => saveProfile()}
                            />
                        </VZDashboardBoxColumn>
                    </VZDashboardBoxFooter>
                </VZLoadingArea>
            </VZDashboardBox>
        </div>
    );

    async function loadProfile() {
        if (!userContext.authToken) {
            return;
        }

        try {
            setIsLoadingProfile(true);

            setProfile(await authAPI.authenticate.getProfile(userContext.authToken));
        } catch (err) {
            toastContext.showError(parseErrorObject(err));
        } finally {
            setIsLoadingProfile(false);
        }
    }

    async function saveProfile() {
        if (profile && userContext.authToken) {
            try {
                setIsSavingProfile(true);

                const maxFileSize = 1024 * 1024 * 10; // 10 MB.

                let iconUrl = undefined;
                if (iconFile) {
                    if (iconFile.type !== 'image/png' && iconFile.type !== 'image/jpeg' && iconFile.type !== 'image/jpg') {
                        throw new Error('Unsupported file type!');
                    }

                    if (iconFile.size > maxFileSize) {
                        throw new Error(`File is too big, max is ${formatMemorySize(maxFileSize)}`);
                    }

                    const iconPostData = await authAPI.authenticate.getIconPostUrl(userContext.authToken, iconFile.type);
                    await uploadFileToS3(iconPostData, iconFile);
                    iconUrl = iconPostData.contentURL;
                }

                setProfile(
                    await authAPI.authenticate.modifyProfile(userContext.authToken, {
                        ...(profile as IVZUserProfile),
                        name: {
                            first: firstName,
                            last: lastName,
                        },
                        title,
                        phoneNumber,
                        bio,
                        iconUrl,
                    })
                );
            } catch (err) {
                console.error(JSON.stringify(err));
                toastContext.showError(parseErrorObject(err));
            } finally {
                setIsSavingProfile(false);
            }
        }
    }
}
