import React, { useState, useEffect, useContext } from 'react';
import { VZModal, ToastContext, UserContext } from 'vzc-client-common';
import IVZTeamRole from 'interfaces/teamRole';
import VZAssignmentTable from 'dashboard/shared/assignment-table';
import VZActionButton from 'dashboard/shared/action-button';
import IVZTeamMember from 'interfaces/teamMember';
import { TeamContext } from 'dashboard/shared/team-context';
import { authAPI, parseErrorObject } from 'vzc-client-common';
import VZLoadingIndicator from 'dashboard/shared/loading-indicator';
import style from './index.module.scss';

type IVZProps = {
    close: () => void;
    onSubmitted: () => void;
    role: IVZTeamRole;
};

export default function VZModifyMembersInRoleModal(props: IVZProps) {
    const teamContext = useContext(TeamContext);
    const toastContext = useContext(ToastContext);
    const userContext = useContext(UserContext);

    const [availableMembers, setAvailableMembers] = useState<IVZTeamMember[]>([]);
    const [assignedMembers, setAssignedMembers] = useState<IVZTeamMember[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchMembers();
    }, []);

    return (
        <VZModal title="Modify Members in Role" closeModal={props.close}>
            <VZAssignmentTable
                className={style.table}
                availableListTitle="Available Members"
                availableSearchPlaceholder="Search Members"
                availableList={availableMembers}
                setAvailableList={setAvailableMembers}
                assignedListTitle="Assigned Members"
                assignedSearchPlaceholder="Search Assigned Members"
                assignedList={assignedMembers}
                setAssignedList={setAssignedMembers}
                search={search}
                getText={getText}
            />
            <VZActionButton label="Save" styleType="ORANGE" disabled={isSubmitting || isLoading} onClick={save} className={style.saveButton} />
            {isSubmitting || (isLoading && <VZLoadingIndicator />)}
        </VZModal>
    );

    async function fetchMembers() {
        if (teamContext.currentTeam && userContext.authToken) {
            setIsLoading(true);
            const members = await authAPI.teams.getMembers(userContext.authToken, teamContext.currentTeam._id);

            const isMemberInRole = (member: IVZTeamMember) => {
                return props.role.users.find((user) => user._id === member.id) != null;
            };

            setAvailableMembers(members.filter((member) => !isMemberInRole(member)));
            setAssignedMembers(members.filter((member) => isMemberInRole(member)));

            setIsLoading(false);
        }
    }

    function getText(member: IVZTeamMember) {
        return `${member.name.first} ${member.name.last}`;
    }

    function search(members: IVZTeamMember[], search: string) {
        return members.filter((member) => search === '' || member.name.first.match(search) || member.name.last.match(search));
    }

    async function save() {
        if (!userContext.authToken) {
            return;
        }

        try {
            setIsSubmitting(true);

            await authAPI.roles.modifyRoleMembers(
                userContext.authToken,
                props.role._id,
                assignedMembers.map((m) => m.id)
            );

            props.close();
            props.onSubmitted();
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
            setIsSubmitting(false);
        }
    }
}
