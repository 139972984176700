import React, { useContext, useState, useEffect, useRef } from 'react';
import { TeamContext } from 'dashboard/shared/team-context/index';
import VZAddTeamModal from 'dashboard/add-team-modal';

import { authAPI, parseErrorObject, removeAtIndex, debounce } from 'vzc-client-common';
import style from './index.module.scss';
import { IVZMyTeam } from 'interfaces/team';
import VZSearchBar from 'dashboard/shared/search-bar';
import addImg from './addTeam.svg';
import { ToastContext, UserContext, VZUserIcon, useDebounce, useLocale } from 'vzc-client-common';
import { IVZMyInvite } from 'interfaces/teamInvite';
import { duration } from 'moment';

interface IVZTeamSelectorPopupProps {
    close: () => void;
}

export default function VZTeamSelectorPopup(props: IVZTeamSelectorPopupProps) {
    const teamContext = useContext(TeamContext);
    const toastContext = useContext(ToastContext);
    const userContext = useContext(UserContext);
    const locale = useLocale();

    const [showAddModal, setShowAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateIndicator, setUpdateIndicator] = useState(0);
    const [myTeams, setMyTeams] = useState<IVZMyTeam[]>([]);
    const [myInvites, setMyInvites] = useState<IVZMyInvite[]>([]);
    const [search, setSearch] = useState('');
    const selectorRef = useRef<HTMLDivElement>(null);
    const debouncedFetch = useDebounce(fetchMyTeams, duration(1, 'seconds'));

    useEffect(() => {
        document.addEventListener('click', onDocumentClick);

        return () => document.removeEventListener('click', onDocumentClick);
    });

    useEffect(() => {
        debouncedFetch();
    }, [search]);

    return (
        <div className={style.container} ref={selectorRef}>
            {showAddModal && <VZAddTeamModal closeModal={() => handleCloseModal()} />}

            <div className={style.scrollContainer}>
                <VZSearchBar
                    search={search}
                    onSearchChanged={setSearch}
                    onSubmit={() => setUpdateIndicator(updateIndicator + 1)}
                    placeholder={locale.formatMessage('dashboard.menu.team_selector.search_placeholder')}
                    searchBarClassName={style.searchBar}
                    isLoading={isLoading}
                />
                {renderMyTeams()}
                {renderMyInvites()}
            </div>
            <div className={style.addTeam} onClick={() => setShowAddModal(true)}>
                <img src={addImg} />
                <div className={style.addTeamText}>{locale.formatMessage('dashboard.menu.team_selector.add_a_team')}</div>
            </div>
        </div>
    );

    async function handleCloseModal() {
        setShowAddModal(false);
        await fetchMyTeams();
        renderMyTeams();
    }

    function renderMyTeams() {
        return myTeams.map((team) => {
            const countMsg = locale.formatMessage('dashboard.menu.team_selector.member_count', {
                count: team.users.length,
            });
            return (
                <div key={team._id} className={style.team} onClick={() => onTeamSelected(team)}>
                    <VZUserIcon className={style.photo} src={team.iconUrl} defaultText={team.name.substr(0, 2)} />
                    <div className={style.label}>
                        {team.name}
                        <br></br>
                        <span className={style.memberCount}>{countMsg}</span>
                    </div>
                </div>
            );
        });
    }

    function renderMyInvites() {
        return myInvites.map((invite) => {
            return (
                <div key={invite._id} className={style.invite}>
                    <img></img>
                    <div className={style.label}>
                        {invite.team.name} ({locale.formatMessage('dashboard.menu.team_selector.invite')})<br></br>
                        <div className={style.buttons}>
                            <div className={style.accept} onClick={() => acceptInvite(invite)}>
                                {locale.formatMessage('dashboard.menu.team_selector.invite.join')}
                            </div>
                            <div className={style.reject} onClick={() => rejectInvite(invite)}>
                                {locale.formatMessage('dashboard.menu.team_selector.invite.reject')}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    async function fetchMyTeams() {
        if (isLoading || !userContext.authToken) {
            return;
        }

        try {
            setIsLoading(true);
            const newMyTeams = await authAPI.teams.getMyTeams(userContext.authToken, search);
            setMyTeams(newMyTeams);

            const newMyInvites = await authAPI.invites.getMyInvites(userContext.authToken, search);
            setMyInvites(newMyInvites);
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
            setIsLoading(false);
        }
    }

    async function acceptInvite(invite: IVZMyInvite) {
        if (!userContext.authToken) {
            return;
        }

        try {
            // Remove the invite so the user can accept many times.
            setMyInvites(removeAtIndex(myInvites, myInvites.indexOf(invite)));

            await authAPI.invites.acceptInvite(userContext.authToken, invite._id, invite.confirmationCode);

            toastContext.showToast(locale.formatMessage('dashboard.menu.team_selector.you_joined_the_team_toast'));
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
        }
    }

    async function rejectInvite(invite: IVZMyInvite) {
        if (!userContext.authToken) {
            return;
        }

        try {
            // Remove the invite so the user can reject many times.
            setMyInvites(removeAtIndex(myInvites, myInvites.indexOf(invite)));

            await authAPI.invites.rejectInvite(userContext.authToken, invite._id, invite.confirmationCode);

            toastContext.showToast(locale.formatMessage('dashboard.menu.team_selector.invite_rejected_toast'));
        } catch (err) {
            const errObj = parseErrorObject(err);
            toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
        } finally {
        }
    }

    function onTeamSelected(team: IVZMyTeam) {
        teamContext.setCurrentTeam(team);
        props.close();
    }

    function onDocumentClick(e: MouseEvent) {
        if (!showAddModal && selectorRef.current && !selectorRef.current.contains(e.target as Node)) {
            props.close();
            e.stopPropagation();
        }
    }
}
