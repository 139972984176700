import React, { useState, ReactNode, useRef, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import style from './index.module.scss';
import { combineClassNames } from 'vzc-client-common';
import triangleImg from './triangle.png';

interface IVZProps {
    children?: ReactNode;
    title: string;
    message?: string;
}

export default function VZTooltipBlock(props: IVZProps) {
    const [showTooltip, setShowTooltip] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    let timeout: number | null = null;

    const customStyle: CSSProperties = {};

    if (blockRef.current) {
        const blockRect = blockRef.current.getBoundingClientRect();
        const width = blockRect.right - blockRect.left;
        const center = blockRect.left + (blockRect.right - blockRect.left) * 0.5;
        customStyle.top = blockRect.bottom + 10;
        customStyle.left = center - width * 0.5;
    }

    return (
        <React.Fragment>
            <div ref={blockRef} className={style.block} onMouseEnter={onMouseEnter} onMouseLeave={onMouseExit}></div>
            {showTooltip && renderTooltip()}
            {props.children}
        </React.Fragment>
    );

    function renderTooltip() {
        let container = document.querySelector('#tooltip');
        if (container) {
            return ReactDOM.createPortal(
                <div className={style.tooltip} style={customStyle}>
                    <img src={triangleImg} className={style.triangle} />
                    <div className={style.header}>
                        <div className={style.text}>{props.title}</div>
                    </div>
                    <div className={style.body}>
                        <div className={style.text}>{props.message}</div>
                    </div>
                </div>,
                container
            );
        } else {
            console.error('Could not mount tooltip, missing element #tooltip!');
            return null;
        }
    }

    function onMouseEnter() {
        if (timeout == null) {
            timeout = window.setTimeout(() => {
                setShowTooltip(true);
            }, 250);
        }
    }

    function onMouseExit() {
        setShowTooltip(false);

        if (timeout) {
            window.clearTimeout(timeout);
            timeout = null;
        }
    }
}
