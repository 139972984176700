import React, { Component, useEffect, useState, useRef } from 'react';

import style from './index.module.scss';
import { overrideClassName, combineClassNames } from 'vzc-client-common';

import { ReactComponent as BrowImg } from './viziya-logo.svg';

interface IVZLoadingIndicatorProps {
    className?: string;
}

export default function VZLoadingIndicator(props: IVZLoadingIndicatorProps) {
    const [opacity, setOpacity] = useState(0.0);
    const opacityRef = useRef(opacity);

    useEffect(() => {
        const timeout = setInterval(() => {
            opacityRef.current = opacityRef.current + opacityRef.current + 0.1;
            setOpacity(opacityRef.current);
        }, 250);

        return () => {
            clearInterval(timeout);
        };
    }, []);

    // return (
    //   <div className={overrideClassName(style.default, props.className)}></div>
    // );
    return (
        <div className={overrideClassName(style.default, props.className)} style={{ opacity: opacity }}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}
