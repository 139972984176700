import React, { Component, useCallback, useContext, useState } from 'react';
import VZNavLink from './nav-link';

import style from './index.module.scss';
import viziyaLogoTop from './viziya-logo.svg';
import viziyaLogoBottom from './viziyaLogoSmall.svg';
import profileIcon from './profile.svg';
import teamsIcon from './teams.svg';
import companyIcon from './company.svg';
import billingIcon from './billing.svg';
import helpIcon from './help.svg';
import logoutIcon from './logout.svg';
import { match, Route } from 'react-router';
import VZTeamSelector from './team-selector';
import { UserContext, useLocale, defaultMessages, authAPI } from 'vzc-client-common';
import * as Sentry from '@sentry/browser';
import { VZAboutModal } from './about-modal';
import { TeamContext } from 'dashboard/shared/team-context';

export interface IVZSideBarNavProps {
    match: match;
}

export default function VZSideBarNav(props: IVZSideBarNavProps) {
    const userContext = useContext(UserContext);
    const locale = useLocale();
    const teamContext = useContext(TeamContext);
    const [showAboutModal, setShowAboutModal] = useState(false);

    return (
        <div className={style.sideBarNav}>
            <img src={viziyaLogoTop} className={style.topLogo} />
            <div className={style.linkContainer}>
                <VZNavLink label={locale.formatMessage('dashboard.menu.teams')} imageSrc={teamsIcon} url={`${props.match.url}/teams`} />
                <Route path={`${props.match.path}/teams`} render={() => <VZTeamSelector />}></Route>
                <VZNavLink label={locale.formatMessage('dashboard.menu.company')} imageSrc={companyIcon} url={`${props.match.url}/company`} />
                <VZNavLink label={locale.formatMessage('dashboard.menu.profile')} imageSrc={profileIcon} url={`${props.match.url}/profile`} />
                {/* <VZNavLink label={locale.formatMessage('dashboard.menu.billing')} imageSrc={billingIcon} url={`${props.match.url}/billing`} />
                <VZNavLink label={locale.formatMessage('dashboard.menu.help')} imageSrc={helpIcon} url={`${props.match.url}/help`} /> */}
                <VZNavLink
                    label={locale.formatMessage('dashboard.menu.logout')}
                    className={style.logout}
                    imageSrc={logoutIcon}
                    url={`${props.match.url}/login`}
                    onClick={logout}
                />
            </div>

            <img className={style.bottomLogo} src={viziyaLogoBottom} onClick={() => setShowAboutModal(true)} />

            {showAboutModal && <VZAboutModal onClose={() => setShowAboutModal(false)} />}
        </div>
    );

    function logout() {
        const authToken = userContext.authToken;

        userContext.logout();

        if (authToken != null) {
            authAPI.authenticate.logout(authToken).catch((err) => {
                Sentry.captureException(err);
            });
        }
    }
}
