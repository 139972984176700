import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import { VZModal, VZDropdown, UserContext } from 'vzc-client-common';
import { combineClassNames, coreAPI, parseErrorObject } from 'vzc-client-common';
import VZActionButton from 'dashboard/shared/action-button';
import { TeamContext } from 'dashboard/shared/team-context';
import IVZConnection from 'interfaces/connection';

import style from './index.module.scss';
import oracleImg from '../oracle-logo.png';

interface IVZConnectionEditProps {
    show: boolean;
    close: () => void;
    submitted?: (newConnection: IVZConnection) => void;
    connectionToEdit: IVZConnection;
}

export default function VZConnectionEditModal(props: IVZConnectionEditProps) {
    const teamContext = useContext(TeamContext);
    const userContext = useContext(UserContext);
    const [errorMsg, setErrorMsg] = useState('');

    const connectionTypeOptions = [
        {
            key: 'ORACLE_CLOUD',
            text: 'Oracle Cloud',
            imgSrc: oracleImg,
        },
        {
            key: 'ORACLE_ON_PREM',
            text: 'Oracle (On Premises)',
        },
        {
            key: 'SAP_CLOUD',
            text: 'SAP Cloud (Coming Soon...)',
        },
        {
            key: 'SAP_ON_PREM',
            text: 'SAP (On Premises)',
        },
        {
            key: 'KUTI_CLOUD',
            text: 'KUTI (Cloud Edition)',
        },
    ];

    const initialValues = {
        name: props.connectionToEdit.name,
        shortName: props.connectionToEdit.shortName,
        type: props.connectionToEdit.ERP,
        connUrl: props.connectionToEdit.baseURL,
    };

    if (props.show) {
        return (
            <VZModal title="Edit Connection" closeModal={props.close}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={async (values, { setSubmitting }) => {
                        if (userContext.authToken && teamContext.currentTeam) {
                            setErrorMsg('');

                            try {
                                const newConnection = await coreAPI.connections.editConnection(
                                    userContext.authToken,
                                    teamContext.currentTeam._id,
                                    props.connectionToEdit._id,
                                    {
                                        name: values.name,
                                        shortName: values.shortName,
                                        ERP: values.type,
                                        baseURL: values.connUrl,
                                    }
                                );

                                props.close();

                                if (props.submitted) {
                                    props.submitted(newConnection);
                                }
                            } catch (err) {
                                const errorObj = parseErrorObject(err);
                                setErrorMsg(`${errorObj.type}: ${errorObj.message}`);
                            }

                            setSubmitting(false);
                        }
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('Required').min(5),
                        shortName: Yup.string().required('Reauired').min(5).max(8),
                        type: Yup.string().required('Required'),
                        connUrl: Yup.string().required('Required').url('Must be a valid URL!'),
                    })}
                >
                    {(formProps) => {
                        const { isValid, values, errors, touched, handleSubmit, setFieldValue, handleChange, isSubmitting, handleBlur } = formProps;
                        return (
                            <React.Fragment>
                                <label className={style.formLabel}>NAME YOUR CONNECTION</label>
                                <input
                                    id="name"
                                    type="text"
                                    className={combineClassNames(style.formTextInput, errors.name && touched.name ? style.error : null)}
                                    placeholder="ENTER NAME"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.name && touched.name && <p className={style.formError}>{errors.name}</p>}

                                <label className={style.formLabel}>SHORT NAME</label>
                                <input
                                    id="shortName"
                                    type="text"
                                    className={combineClassNames(style.formTextInput, errors.shortName && touched.shortName ? style.error : null)}
                                    placeholder="ENTER SHORT NAME"
                                    value={values.shortName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.shortName && touched.shortName && <p className={style.formError}>{errors.shortName}</p>}

                                <label className={style.formLabel}>TYPE</label>
                                <VZDropdown
                                    options={connectionTypeOptions}
                                    selectedValue={values.type}
                                    setSelectedValue={(newValue) => setFieldValue('type', newValue)}
                                />

                                <label className={style.formLabel}>CONNECTION</label>
                                <input
                                    id="connUrl"
                                    type="text"
                                    className={combineClassNames(style.formTextInput, errors.connUrl && touched.connUrl ? style.error : null)}
                                    placeholder="ENTER CONNECTION URL"
                                    value={values.connUrl}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.connUrl && touched.connUrl && <p className={style.formError}>{errors.connUrl}</p>}

                                <VZActionButton
                                    label="ADD CONNECTION"
                                    styleType="ORANGE"
                                    className={style.saveButton}
                                    disabled={!isValid && !isSubmitting}
                                    onClick={handleSubmit}
                                />

                                {errorMsg != '' && <p className={style.formError}>{errorMsg}</p>}
                                {isSubmitting && <p className={style.formStatus}>Creating...</p>}
                            </React.Fragment>
                        );
                    }}
                </Formik>
            </VZModal>
        );
    }
    return null;
}
