import React, { useContext, useState } from 'react';

import { TeamContext } from 'dashboard/shared/team-context';

import { authAPI, parseErrorObject } from 'vzc-client-common';
import VZDashboardTable, { useDashboardTable, IVZDashboardTableColumn } from 'dashboard/shared/dashboard-table';
import IVZTeamRole from 'interfaces/teamRole';
import VZRowButton, { VZRowButtonType } from 'dashboard/shared/row-button';
import VZLabelWithEditButton from 'dashboard/shared/label-with-edit-button';
import VZModifyMembersInRoleModal from '../modify-members-in-role-modal';
import VZModifyRolePackagesModal from '../modify-role-packages-modal';
import { VZPrompt, ToastContext, UserContext } from 'vzc-client-common';
import { VZModifyRoleNameModal } from '../modify-role-name-modal';

export default function VZRoleList() {
    const teamContext = useContext(TeamContext);
    const toastContext = useContext(ToastContext);
    const userContext = useContext(UserContext);

    const columns: IVZDashboardTableColumn<IVZTeamRole>[] = [
        {
            columnId: 'NAME',
            label: 'NAME',
            width: 300,
            render: (role, classes) => {
                return (
                    <VZLabelWithEditButton
                        label={role.name}
                        onEditClick={() => {
                            setShowChangeRoleName(true);
                            setEditRole(role);
                        }}
                    />
                );
            },
        },
        {
            columnId: 'MEMBERS',
            label: 'MEMBERS',
            width: 300,
            render: (role) => {
                return (
                    <VZLabelWithEditButton
                        label={`${role.users.length} Members`}
                        onEditClick={() => {
                            setShowChangeRoleMemebrs(true);
                            setEditRole(role);
                        }}
                    />
                );
            },
        },
        {
            columnId: 'PACKAGES',
            label: 'PACKAGES',
            width: 300,
            render: (role) => {
                return (
                    <VZLabelWithEditButton
                        label={`${role.packages.map((p) => p.name).join(',')}`}
                        onEditClick={() => {
                            setShowChangeRolePackages(true);
                            setEditRole(role);
                        }}
                    />
                );
            },
        },
        {
            columnId: 'ACTIONS',
            label: 'ACTIONS',
            minWidth: 205,
            width: 200,
            flexGrow: 1,
            render: (role, classes) => {
                return (
                    <div className={classes.actions}>
                        {teamContext.isAdminOfCurrentTeam() && (
                            <VZRowButton
                                actionType={VZRowButtonType.DELETE}
                                onClick={() => {
                                    setEditRole(role);
                                    setShowDeleteRolePrompt(true);
                                }}
                            />
                        )}
                    </div>
                );
            },
        },
    ];

    const tableProps = useDashboardTable(columns, fetchRoles, [teamContext.currentTeam]);
    const [showChangeRoleMembers, setShowChangeRoleMemebrs] = useState(false);
    const [showChangeRolePackages, setShowChangeRolePackages] = useState(false);
    const [showDeleteRolePrompt, setShowDeleteRolePrompt] = useState(false);
    const [showChangeRoleName, setShowChangeRoleName] = useState(false);
    const [editRole, setEditRole] = useState<IVZTeamRole | null>(null);

    return (
        <React.Fragment>
            {showChangeRoleMembers && editRole && (
                <VZModifyMembersInRoleModal role={editRole} close={() => setShowChangeRoleMemebrs(false)} onSubmitted={tableProps.refresh} />
            )}

            {showChangeRolePackages && editRole && (
                <VZModifyRolePackagesModal role={editRole} close={() => setShowChangeRolePackages(false)} onSubmitted={tableProps.refresh} />
            )}

            {showChangeRoleName && editRole && (
                <VZModifyRoleNameModal role={editRole} onSave={tableProps.refresh} close={() => setShowChangeRoleName(false)} />
            )}

            {showDeleteRolePrompt && editRole && (
                <VZPrompt
                    title="Delete Role"
                    message="Are you sure you want to delete role?"
                    okText="Yes"
                    okStyleType="DELETE"
                    onOk={() => deleteRole()}
                    cancelText="Cancel"
                    onCancel={() => setShowDeleteRolePrompt(false)}
                />
            )}

            <VZDashboardTable searchBarPlaceholder="Search for Roles" countSuffix={'dashboard.teams.roles.count'} {...tableProps} />
        </React.Fragment>
    );

    async function fetchRoles(search?: string) {
        if (teamContext.currentTeam && userContext.authToken) {
            return await authAPI.roles.getRoles(userContext.authToken, teamContext.currentTeam._id, search);
        } else {
            return [] as IVZTeamRole[];
        }
    }

    async function deleteRole() {
        if (!userContext.authToken) {
            return;
        }

        if (editRole) {
            try {
                await authAPI.roles.deleteRole(userContext.authToken, editRole._id);

                toastContext.showToast('Role Deleted');

                tableProps.refresh();
            } catch (err) {
                const errObj = parseErrorObject(err);
                toastContext.showToast(`${errObj.type}: ${errObj.message}`, 'ERROR');
            } finally {
                setShowDeleteRolePrompt(false);
            }
        }
    }
}
