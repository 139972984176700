import React from 'react';

import style from './index.module.scss';

import emailImg from './email.svg';

export function VZEmailConfirmBlock(props: { email: string; actionText: string }) {
    return (
        <div className={style.container}>
            <div className={style.header}>
                <h3 className={style.headerTitle}>Let us make sure it’s you.</h3>
            </div>
            <div className={style.body}>
                <img src={emailImg} className={style.emailIcon} alt="Email Icon" />
                <h4 className={style.bodyTitle}>Email Verification</h4>
                <hr className={style.confirmtationHR} />
                <p className={style.bodyText}>
                    A confirmation email has been sent to <span className={style.bodyText}>{props.email}</span>.
                </p>
                <p className={style.bodyText}>{props.actionText}</p>
            </div>
        </div>
    );
}

export default VZEmailConfirmBlock;
