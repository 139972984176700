import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import style from './index.module.scss';

interface IVZNavTabBarTab {
    label: string;
    url: string;
}

interface IVZNavTabBarProps {
    tabs: IVZNavTabBarTab[];
}

interface IVZNavTabBarState {}

export default class VZNavTabBar extends Component<IVZNavTabBarProps, IVZNavTabBarState> {
    getTabs() {
        return this.props.tabs.map((tab) => (
            <NavLink activeClassName={style.active} key={tab.label} className={style.link} to={tab.url}>
                {tab.label}
            </NavLink>
        ));
    }

    render() {
        return <div className={style.container}>{this.getTabs()}</div>;
    }
}
